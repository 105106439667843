
module.exports = {
  icpCard: "", // icp证
  /**
   * 高德地图申请链接
   * 添加成功后，可获取到key值和安全密钥jscode（自2021年12月02日升级，升级之后所申请的 key 必须配备安全密钥 jscode 一起使用)
   */
  //FIXME 请检查当前高德key创建的日期，如果2021年12月02日之前申请的 无需填写安全密钥
  aMapSecurityJsCode:"fb1d6d46e2950c8fb31144ca7ff1a1d8", // 高德web端js申请的安全密钥
  aMapKey: "7237e13cf35adeeb38f4842249c3be12", //高德web端js申请的api key
  enableCDN: true, //生产环境 是否启用cdn加载 vue等js
  port: 10000, //端口
};
