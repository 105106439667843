<template>
    <div class="get-box">
        <el-button class="get-code" type="danger" :disabled="comDisabled" @click="getCode" round>
<!--            {{ text == '获取验证码' ? text : text + 's后重新获取' }}-->
          {{ text == $t('login.hqyzm') ? text : text + 's'+ $t('login.get') }}
        </el-button>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            default: true
        },
        isHave: {
            default: false
        }
    },
    name: "getCode.vue",
    data() {
        return {
            text: this.$t('login.hqyzm'),
            timer: '',
            comDisabled:true
        }
    },
    mounted() {
        this.comDisabled = this.disabled
    },
    methods: {
        getCode() {
            this.$emit('getCode')
            this.text = 60
            this.time()
        },
        time() {
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                this.text -= 1
                if (this.text == 0) {
                    clearInterval(this.timer)
                    this.text = this.$t('login.hqyzm')
                    this.$emit("again")
                }
            }, 1000)
        },
    },
    watch: {
        isHave(val) {
            if (val) {
                this.text = 60
                this.time()
            }
        },
        disabled(val){
            this.comDisabled = val
        }
    }
}
</script>

<style scoped>
.get-box {
    width: 100%;
}

.get-code {

}

</style>