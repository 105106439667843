<template>
  <div>
    <header class="header header1 clearfix" id="header">
      <top-nav/>
    </header>
    <div class="side-bar">
      <el-row class="tac">
        <el-col>
          <div class="user">
            <img :src="userInfo.headImg" alt="" />
            <p>{{ userInfo.phone }}</p>
          </div>
          <el-menu
            :default-active="activePath"
            class="el-menu-vertical-demo"
            router
            @open="handleOpen"
            @close="handleClose"
          >
            <el-submenu index="1" v-for="(item, index) in order" :key="index">
              <template slot="title">
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group v-for="(i, j) in item.menus" :key="j">
                <el-menu-item
                  route
                  :index="'/' + i.path"
                  @click="activeItem('/' + i.path)"
                  >{{ i.title }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item index="/shopping">
              <!-- <i class="el-icon-menu"></i> -->
              <span slot="title">{{$t('menu.gwc')}}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
      <div class="layout">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '../../components/common/topNav.vue'
export default {
  name: "menus",
  components: {TopNav},
  data() {
    return {
      userInfo: [],
      order: [
        {
          icon: "",
          title: this.$t('menu.ddzx'),
          menus: [
            {
              icon: "",
              title: this.$t('menu.wddd'),
              id: "1-1",
              path: "MyOrder",
            },
            {
              icon: "",
              title: this.$t('menu.shdz'),
              id: "1-2",
              path: "MyAddress",
            },
          ],
          display: true,
        },
      ],
      activePath: "",
    };
  },
  created() {
    this.activePath = window.sessionStorage.getItem("activePath");
  },

  mounted() {
    let userMap = JSON.parse(localStorage.getItem("userMap"));
    this.userInfo = userMap;
  },

  methods: {
    menuItem(item) {
      console.log(item.path);
      this.$router.push({
        path: `/${item.path}`,
      });
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleOpen(key, keyPath) {
      //多级菜单时默认显示第一个
    },

    activeItem(path) {
      window.sessionStorage.setItem("activePath", path);
      this.activePath = path;
    },
    shopping() {
      this.$router.push({
        path: "/shopping",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #eee;
  img {
    margin-bottom: 15px;
    width: 96px;
    height: 96px;
    border-radius: 48px;
  }
  p {
    font-size: 14px;
  }
}
.side-bar {
  margin-top: 150px;
  width: 100%;
  height: 100%;
  display: flex;
  .tac {
    width: 15%;
  }
  .layout {
    width: 85%;
  }
}
.login {
  margin-top: 2px;
  position: relative;
  color: #fff !important;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;

  .name {
    z-index: 99;
    margin-top: 20px;
  }
  .dropdown {
    width: 100px;
    height: 80px;
    background: #7f0114;
    position: absolute;
    top: 26px;
    left: 0px;
    // border-radius: 10px;
    border-radius: 0 0 10px 10px;
    padding: 10px;
    display: none;
    z-index: 99;
  }
}
.login :hover {
  transition: 0.5s;
}
.login:hover .dropdown {
  display: block;
}
.tickets {
  margin-top: 2px;
  cursor: pointer;
}
.el-icon-shopping-cart-full {
  cursor: pointer;
}
.top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  background: #7F0114;
  color: #fff;
  padding: 0 20px;
  .platform {
    display: flex;
    .username {
      margin-top: 2px;
      margin-right: 15px;
    }
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  ul {
    display: flex;
    li {
      width: 100px;
      margin-right: 5%;
      a{
        color: #fff;
      }
    }
  }
}
}
.header {
  height: 45px;
}
@media screen and (max-width: 750px) {
  .side-bar {
    display: block;
    .tac {
      width: 100%;
    }
    .layout {
      width: 100%;
      
    }
  }
  .pay {
    padding: 0 15px !important;
  }
}
</style>
<style>
.el-menu-item.is-active {
  color: #7f0114 !important;
}
</style>