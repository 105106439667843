// 阿拉伯语
module.exports = {
  banner: {
    ljlj: ' فهم التفاصيل ',
  },
  header: {
    bbzn: 'BaBa سمارت',
    hyn: 'مرحبا بكم !',
    wddd: 'أمر',
    gwc: 'العربة',
    ptdl: ' منصة تسجيل الدخول',
    qdl: 'سجل',
    tcdl: 'تسجيل الخروج',
    dhl: 'شريط التنقل'
  },
  nav: {
    bbwh: "ضع الثقافة",
    home: "الصفحة الرئيسية",
    flag: " { من } اسم { ما }",
    zhcs: 'المدينة الذكية',
    zhgc: 'مصنع الذكية',
    zhxf: 'الحكمة النار',
    zhgd: 'الحكمة الموقع',
    zhsq: 'الاستخبارات',
    zhxy: 'الحكمة الحرم الجامعي',
    bbdt: 'وضع ديناميكية',
    zwcx: "من صحة الاستعلام",
    zlxz: "تحميل المعلومات",
    rjxz: "تحميل البرمجيات",
    gysly: "بائع الرسالة",
    khly: "تعليق العميل",
    bbjj: "وضع مقدمة",
    ryzz: "شرف",
    lxwm: "الاتصال بنا",
    hydt: 'ديناميات الصناعة',
    bbbk: 'وضع ويكيبيديا',
    cpzx: 'منتجات',
    jjfa: 'الحل',
    xmal: 'المشاريع',
    bbzx: 'وضع المعلومات',
    fwzc: 'الخدمات',
    zsdl: 'مستثمر',
    gybb: 'عنا',
    zxsp: 'فيديو : .',
    hylt: 'المنتديات',
    yhxy:'بروتوكول المستخدم',
    ystk:'شروط الخصوصية',
    fhtkzc:'سياسة استرداد الشحن'
  },
  product: {
    ljgm: ' شراء الآن',
    cpzxsy: 'مركز المنتجات',
    znaqm: 'خوذة ذكية',
    ywbjq: 'الدخان الانذار',
    dwsb: 'مشاهدة المواقع',
    zhyd: 'الحكمة الكهرباء',
    znsb: 'العداد الذكي',
    znpt: 'منصة الذكية',
    yyfc: 'على الطرق الوعرة شاحنة قلابة',
    xfcl: 'مركبات مكافحة الحرائق',
    ss: 'بحث'
  },
  footer: {

  },
  solution: {
    ckgd: 'شاهد المزيد'
  },
  common: {
    sy: 'الصفحة الرئيسية',


  },
  breadcrumb: {
    xwdt: ' أخبار ديناميكية',
    bbdt: 'وضع ديناميكية',
    hydt: 'ديناميات الصناعة',
    bbbk: 'وضع ويكيبيديا',
    ljxq: 'التعرف على المزيد'
  },
  serve: {
    fwzc: 'خدمة الدعم',
    zlxz: 'تحميل المعلومات',
    rjxz: 'تحميل البرمجيات',
    gysly: 'بائع الرسالة',
    zwcx: 'من صحة الاستعلام',
    zwcx_placeholder: 'الرجاء إدخال المنتجimei',
    cpxx: "معلومات المنتج",
    pcy: " ضمان الجودة / سوبر طويلة ضمان / عملية خالية من القلق",
    cpxh: " نموذج المنتج",
    ys: "اللون",
    sbh: "رقم الجهاز",
    ccrq: "تاريخ الإنتاج ",
    sccj: "الصانع ",
    zjy: 'مفتش الجودة',
    xsryxm: "اسم البائع",
    dlsmc: "اسم الوكيل ",
    gwrk: " بوابة الموقع الرسمي",
    yhxy:'بروتوكول المستخدم',
    ystk:'شروط الخصوصية',
    fhtkzc:'سياسة استرداد الشحن'
  },
  goodsDetail: {
    price: 'ثمن ',
    number: 'كم ',
    addgoodscat: 'الانضمام إلى عربة التسوق ',
    buynow: ' شراء الآن '
  },
  safetyHat: {
    cpzxsy: 'مركز المنتج الصفحة الرئيسية',
    ss: 'بحث',
    buynow: 'شراء الآن'
  },
  fireEngine: {
    cpzxsy: 'مركز المنتج الصفحة الرئيسية',
    ss: 'بحث',
    buynow: 'شراء الآن'
  },
  platForm: {
    cpzxsy: 'مركز المنتج الصفحة الرئيسية',
    ss: 'بحث',
    buynow: 'شراء الآن'
  },
  smartElectricity: {
    cpzxsy: 'مركز المنتج الصفحة الرئيسية',
    ss: 'بحث',
    buynow: 'شراء الآن'
  },
  smokeDetector: {
    cpzxsy: 'مركز المنتج الصفحة الرئيسية',
    ss: 'بحث',
    buynow: 'شراء الآن'
  },
  touringCar: {
    cpzxsy: 'مركز المنتج الصفحة الرئيسية',
    ss: 'بحث',
    buynow: 'شراء الآن'
  },
  watch: {
    cpzxsy: 'مركز المنتج الصفحة الرئيسية',
    ss: 'بحث',
    buynow: 'شراء الآن'
  },
  waterMeter: {
    cpzxsy: 'مركز المنتج الصفحة الرئيسية',
    ss: 'بحث',
    buynow: 'شراء الآن'
  },
  dynamic: {
    home: "الصفحة الرئيسية",
    xwdt: ' أخبار ديناميكية'
  },
  dynamicDetail: {
    zz: "كاتب"
  },
  dynamicBox: {
    ljxq: 'التعرف على المزيد'
  },
  supplier: {
    qymc: ' اسم المؤسسة ',
    qcrqymc: 'أدخل اسم المؤسسة ',
    xm: "الاسم",
    qsrxm: "الرجاء إدخال اسم ",
    yx: 'صندوق البريد ',
    qsuyx: 'الرجاء إدخال صندوق البريد',
    dh: 'الهاتف',
    qsrdh: "الرجاء إدخال رقم الهاتف ",
    nr: 'الرجاء إدخال رقم الهاتف ',
    qsrnr: "الرجاء إدخال المحتوى",
    tj: "تقديم",
    cz: 'تشيكوسلوفاكيا',
    qtxzqddhhm: 'يرجى ملء رقم الهاتف الصحيح',
    qtxzqdyx: 'يرجى ملء علبة البريد الصحيحة',
    qsrqymc: 'الرجاء إدخال اسم المؤسسة',
    qsrsjh: "الرجاء إدخال رقم الهاتف المحمول",
  },
  agentForm: {
    xm: "الاسم",
    qsrxm: "الرجاء إدخال اسم ",
    yx: 'صندوق البريد ',
    qsuyx: 'الرجاء إدخال صندوق البريد',
    dh: 'الهاتف',
    qsrdh: "الرجاء إدخال رقم الهاتف ",
    nr: 'الرجاء إدخال رقم الهاتف ',
    qsrnr: "الرجاء إدخال المحتوى",
    tj: "تقديم",
    cz: 'تشيكوسلوفاكيا',
    qtxzqddhhm: 'يرجى ملء رقم الهاتف الصحيح',
    qtxzqdyx: 'يرجى ملء علبة البريد الصحيحة',
    qsrqymc: 'الرجاء إدخال اسم المؤسسة',
    qsrsjh: "الرجاء إدخال رقم الهاتف المحمول",
  },
  about: {
    dh: "الهاتف",
    yx: "البريد",
    zbgsdz: 'عنوان المكتب الرئيسي',
    zbgsmc:'اسم الشركة',
    fbgsmc:'اسم الشركة',
    fbgsdz:'توزيع عنوان الشركة'
  },
  footerContent: {
    jzhxz: 'جيا تشى هوى download',
    gsdzvalue: "Building 11, 4th Floor, Tangtou Nangang Third Industrial Park, Tangtou Community, Shiyan Street, Bao'an District, Shenzhen",
    qgzxfwrx: "الخط الساخن الوطني",
    gsdz: "عنوان الشركة",
    gzwxgzh: "اتبع حساب WeChat العام",
    gzwb: "اتبع ويبو",
    wxgzh: "حساب WeChat العام",
    APPxz: "تنزيل التطبيق",
    bq: "سوف شنتشن وضع التكنولوجيا الذكية المحدودة جميع الحقوق محفوظة",
    xz: "تنزيل التطبيق",
    znsfcp: "منتجات العدالة الذكية",
    znxfcp: 'منتجات الحماية من الحرائق الذكية',
    znafcp: 'منتجات الأمان الذكية',
    znshcp: 'منتجات الحياة الذكية',
    znpj: 'الملحقات الذكية',
    znglpt: 'منصة الإدارة الذكية',
    zhcs: 'المدينة الذكية',
    zhgc: 'المصنع الذكي',
    zhxf: 'المصنع الذكي',
    zhgd: 'موقع البناء الذكي',
    zhsq: 'المجتمع الذكي',
    zhxy: 'الحرم الجامعي الذكي',
    jjfa: 'حلول',
    cpzxsy: 'موطن المنتجات',
    bbzx: 'معلومات',
    bbdt: 'ضع الديناميكي',
    hydt: 'أخبار الصناعة',
    bbbk: 'ضع الموسوعة',
    fwzc: 'دعم الخدمة',
    bbjj: "ضع المقدمة",
    ryzz: "الشرف",
    gybb: 'حول المقبض',
    bbwh: "ضع الثقافة",
    lxwm: "اتصل بنا",
    hzhb: "شريك",
    zlxz: 'تنزيل البيانات',
    rjxz: 'تنزيل البيانات',
    gysly: 'تنزيل البرنامج',
    zwcx: 'استعلام الأصالة',
    khly: "رسالة العميل",
    yhxy:'بروتوكول المستخدم',
    ystk:'شروط الخصوصية',
    fhtkzc:'سياسة استرداد الشحن'
  },
  login: {
    bbzn: "ضع الذكاء",
    sjh: 'رقم الهاتف المحمول',
    mm: 'كلمة المرور',
    zczh: "تسجيل حساب",
    dl: 'تسجيل الدخول',
    qdl: "الرجاء تسجيل الدخول",
    zc: 'تسجيل',
    zh: "رقم الحساب",
    qsrzh: 'الرجاء إدخال رقم الحساب',
    qsrmm: 'الرجاء إدخال كلمة المرور',
    qrmm: 'تأكيد كلمة المرور',
    qzcsrmm: "الرجاء إدخال كلمة المرور مرة أخرى",
    qsrsjh: "الرجاء إدخال رقم هاتفك المحمول",
    yzm: "كلمة التحقق",
    qsryzm: "الرجاء إدخال رمز التحقق",
    cd: "يجب أن يكون الطول بين 6-12 بت",
    qtxzqdsjhm: "يرجى ملء رقم الهاتف المحمول الصحيح",
    mmtj: "يجب أن تحتوي كلمة المرور على أرقام وحروف ، 6-20 رقما",
    mmbyz: 'عدم تناسق كلمة المرور',
    hqyzm: "احصل على كلمة التحقق",
    get: ' بعد إعادة الجلب'
  },
  logisticsInfo: {
    wlxq: "التفاصيل اللوجستية",
    ydhm: "رقم بوليصة الشحن",
    wlgs: "شركة لوجستية",
    kfdh: "رقم خدمة العملاء",
    wlgsgfwz: "الموقع الرسمي لشركات الخدمات اللوجستية",
    zwwlxx: "لا توجد معلومات لوجستية"
  },
  MyAddress: {
    shdz: "عنوان التسليم",
    tjxdz: "إضافة عنوان جديد",
    mr: "الافتراضي",
    xg: "تعديل",
    sc: "حذف",
    shr: "المرسل إليه",
    shdq: "منطقة الاستقبال",
    xxdz: "العنوان الكامل",
    sjhm: "رقم الهاتف المحمول",
    scdzcg: "تم حذف العنوان بنجاح",
    xz: "تحديد",
    dzbm: "الاسم المستعار للعنوان",
    mrdz: "العنوان الافتراضي",
    qsrsjrxm: "الرجاء إدخال اسم المستلم",
    qsrdz: "الرجاء إدخال العنوان",
    qsrxxdz: "الرجاء إدخال العنوان الكامل",
    sjhbnwk: "لا يمكن أن يكون رقم الهاتف المحمول فارغا",
    sjhgscc: "تنسيق رقم الهاتف المحمول خاطئ",
    xgdzcg: "تم تغيير العنوان بنجاح",
    xinz: "جديد",
    tjdzcg: "تمت إضافة العنوان بنجاح"
  },
  MyOrder: {
    dd: "النظام",
    qbdd: "جميع الطلبات",
    dfk: "معلق",
    dfh: "سيتم شحنها",
    dsh: "سيتم استلامها",
    ywc: "مكتمل",
    my: "لكل صفحة",
    tiao: "مقال"
  },
  orderDetail: {
    ddxq: "تفاصيل الطلب",
    ddh: "رقم الطلب",
    cjsj: "وقت الخلق",
    shrx: "معلومات المرسل إليه",
    shr: "المرسل إليه",
    sjhm: "رقم الهاتف المحمول",
    shdz: "عنوان التسليم",
    cptp: "صور المنتج",
    cpmc: "اسم المنتج",
    dj: "سعر الوحدة",
    sl: "الكمية",
  },
  pay: {
    shrxx: "معلومات المرسل إليه",
    glshrdz: "إدارة عناوين المرسل إليه",
    xg: "تعديل",
    sc: "حذف",
    tjxdz: "إضافة عنوان جديد",
    sqdz: "طي العنوان",
    gddz: "المزيد من العناوين",
    spxx: "معلومات المنتج",
    fhgwc: "العودة إلى عربة التسوق",
    yh: "متوفر في المخزون",
    wu: "غير متوفر",
    ddbz: "ملاحظات الطلب",
    ts: "نصيحة: من فضلك لا تملأ معلومات حول الدفع أو الاستلام ،",
    tjdd: "إرسال الطلب",
    psz: "السفينة إلى",
    shdz: "عنوان التسليم",
    shr: "المرسل إليه",
    zffs:'طريقة الدفع',
    qxzndzffs:"الرجاء اختيار طريقة الدفع الخاصة بك",
    jnzf:'المدفوعات المحلية',
    jwzf:'الدفع في الخارج',
    qx:'ألغى',
    qr:'أكّد'
  },
  payment: {
    ddtjcg: "تم إرسال الطلب بنجاح ، يرجى الدفع في أقرب وقت ممكن!",
    qnjkwczf: "يرجى إكمال الدفع في أقرب وقت ممكن ، وإلا سيتم إلغاء الطلب تلقائيا",
    dycsqx: "تم إلغاء الطلب بعد انتهاء المهلة",
    yfje: "المبلغ المستحق",
    yuan: "يوان",
    qsywxsmzf: "الرجاء استخدام رمز مسح ويتشات للدفع",
    zfcghzdtz: "القفز التلقائي بعد الدفع الناجح ، إذا كنت تريد القفز ، يرجى النقر فوق الزر للقفز يدويا",
    cxzf: "السداد",
    zfb: "علي باي",
    wx: "ويتشات",
    zfcg: 'تم الدفع بنجاح'
  },
  shopping: {
    tp: "صورة",
    spmc: "الاسم التجاري",
    dj: "سعر الوحدة",
    xj: "المجموع الفرعي",
    sl: "الكمية",
    cz: "العملية",
    jxll: "متابعة التصفح",
    gong: "غونغ",
    jcp: "قطعة المنتج",
    hj: "المجموع",
    qjs: "اذهب إلى التسوية",
    cczjycgsp: "سيؤدي هذا الإجراء إلى إزالة المنتج ، هل تريد المتابعة؟",
    ts: "تلميح",
    qr: "تأكيد",
    qx: "إلغاء",
    yqxyc: "غير مزالة",
    ycspcg: "تمت إزالة العنصر بنجاح",
    xgslcg: "عدد التعديلات الناجحة",
    qxznyjsdsp: "يرجى تحديد السلعة التي تريد التحقق منها",

  },
  menu: {
    gwc: "عربة التسوق",
    ddzx: "مركز الطلبات",
    wddd: "طلباتي",
    shdz: "عنوان الحصاد",

  },
  noLogin: {
    ljzf: 'دفع',
    qxss: "العلامة التجارية الجديدة",
    sl: "الكمية",
    yfje: "المبلغ المستحق",
    gmxx: "معلومات الشراء",
    shxx: "معلومات الإيصال",
    shr: "المرسل إليه",
    qsrndzwmhywm: "الرجاء إدخال اسمك الصيني أو الاسم الإنجليزي",
    shdz: "عنوان التسليم",
    qsrndshdz: "الرجاء إدخال عنوان الحصاد الخاص بك",
    lxdh: "رقم الاتصال",
    qsrndlxfs: "الرجاء إدخال تفاصيل الاتصال الخاصة بك",
    yzm: "كلمة التحقق",
    qsryzm: "الرجاء إدخال رمز التحقق",
    qsrzqdsjh: "الرجاء إدخال رقم الهاتف المحمول الصحيح",
    zfb: "علي باي",
    wx: "WeChat",
    gsjhybzy: "رقم الهاتف هذا مأخوذ بالفعل",
    gsjhmyzcqdl: "رقم الهاتف المحمول هذا مسجل ، يرجى تسجيل الدخول"
  },
  home: {
    tjcp: "المنتجات الموصى بها",
    ckgd: "شاهد المزيد",
    jjfa: "الحل",
    bbznglpt: "وضع منصة الإدارة الذكية",
    xmal: "حالة المشروع",
    zlhzhb: "شريك استراتيجي",
    yljh: "الروابط",
    bbscrk: "ضع مدخل المركز التجاري",
    bbptdl: "تسجيل دخول النظام الأساسي",
    gysrk: "دخول المورد",
    hzhbrk: "بوابة الشركاء",
    zsjmrk: "دخول دخول الاستثمار",
  },
  orderBox: {
    ddh: "رقم الطلب",
    cjsj: "وقت الخلق",
    ddxq: "تفاصيل الطلب",
    dzf: "يجب دفعها",
    sdgb: "الاغلاق اليدوي",
    qxdd: "إلغاء الطلب",
    wzf: "غير مدفوعة",
    chgb: "مهلة",
    zfsb: "فشل الدفع",
    qzf: "اذهب وادفع",
    ckcg: "ناجح في الخارج",
    ywc: "مكتمل",
    kcwl: "عرض الخدمات اللوجستية",
    qrsh: "تأكيد الاستلام",
    qxzqxddyy: "يرجى تحديد سبب للإلغاء",
    qx: "إلغاء",
    qr: "تأكيد",
    qrgb: "تأكيد الإغلاق",
    qxddcg: "تم إلغاء الطلب بنجاح",
    nqryshm: "هل أنت متأكد من أنك تريد أخذ البضائع؟",
    ts: "تلميح",
    yqxsh: "ملغاة",
    wldt:'اللوجستية الحيوية',
    sqsh:'تطبيق ما بعد البيع',
    shfw:'خدمة ما بعد البيع',
    qxzshfw:'الرجاء اختيار نوع ما بعد البيع',
    gzjj:'حل مشكلة',
    hh:'تبادل',
    thtk:'رد',
    tkbth:'رد لا عودة'
  }

}
