import Vue from 'vue'
import {
    Button,
    Notification,
    Select,
    Option,
    Backtop,
    Message,
    Loading,
    InputNumber,
    Form,
    FormItem,
    Input,
    MessageBox,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Row,
    Col,
    Table,
    TableColumn,
    Popover,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Dialog,
    Switch,
    Badge,
    Tabs,
    TabPane,
    Pagination,
    Radio,
    RadioGroup,
    RadioButton,
    Card,
    Upload,
    Breadcrumb,
    BreadcrumbItem,
    CollapseTransition,
    Image,
    Timeline,
    TimelineItem,
    Progress,
    Dropdown,
    Collapse,
    CollapseItem,
    Steps,
    Step,
} from 'element-ui'
Vue.use(Pagination);
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.use(Option)
Vue.use(Button)
Vue.use(Select)
Vue.use(Backtop);
Vue.use(InputNumber);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Dialog);
Vue.use(Switch);
Vue.use(Badge);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Card);
Vue.use(Upload);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Image);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Progress);
Vue.use(Dropdown);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Steps);
Vue.use(Step);
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;