// 法语
module.exports = {
  banner: {
    ljlj: 'Découvrez les détails',
  },

  header: {
    bbzn: 'BaBa Intelligent',
    hyn: 'bienvenue！',
    wddd: 'Commandes',
    gwc: 'panier',
    ptdl: 'connexion à la plateforme',
    qdl: 'Se connecter',
    tcdl: 'se déconnecter',
    dhl: 'barre de navigation'
  },
 
  nav: {
    bbwh: "Mettre la culture",
    home: "accueil",
    flag: "{qui} le nom est {quoi}",
    zhcs: 'Une ville intelligente',
    zhgc: 'Smart factory',
    zhxf: 'La sagesse du feu',
    zhgd: 'La sagesse du site',
    zhsq: 'De la communauté du renseignement',
    zhxy: 'La sagesse campus',
    bbdt: 'Mettre la dynamique',
    zwcx: "L'authenticité de la requête",
    zlxz: "Télécharger de l'information",
    rjxz: "Logiciels télécharger",
    gysly: "Fournisseur de message",
    khly: "Commentaire",
    bbjj: "Mis introduction",
    ryzz: "L'honneur",
    lxwm: "Contactez-nous",
    hydt: "La dynamique de l'industrie",
    bbbk: 'Mettre Wikipédia',
    cpzx: 'Produits',
    jjfa: 'Solutions',
    xmal: 'Projets',
    bbzx: "Informations",
    fwzc: 'Services',
    zsdl: "Investisseurs",
    gybb: "À propos de nous",
    zxsp: 'Vidéo',
    hylt: "BBS",
    yhxy:'Accord utilisateur',
    ystk:'Conditions de confidentialité',
    fhtkzc:"Politique de remboursement d'expédition",
  },
  product: {
    ljgm: 'Détails',
    cpzxsy: 'Produits',
    znaqm: 'Smart casque',
    ywbjq: 'Détecteur de fumée',
    dwsb: 'Le Positionnement De La Montre',
    zhyd: "La sagesse de l'électricité",
    znsb: 'Compteur intelligent',
    znpt: 'Smart plate-forme',
    yyfc: 'Off-road dumper',
    xfcl: "De lutte contre l'incendie des véhicules",
    ss: 'recherche'
  },
  footer: {

  },
  solution: {
    ckgd: 'voir plus'
  },
  common: {
    sy: 'accueil',


  },
  breadcrumb: {
    xwdt: 'Actualités',
    bbdt: 'Mettre la dynamique',
    hydt: "La dynamique de l'industrie",
    bbbk: 'Mettre Wikipédia',
    ljxq: 'en savoir plus'
  },
  serve: {
    fwzc: 'Service de soutien',
    zlxz: "Télécharger de l'information",
    rjxz: 'Logiciels télécharger',
    gysly: "Fournisseur de message",
    yhxy:'Accord utilisateur',
    ystk:'Conditions de confidentialité',
    fhtkzc:"Politique de remboursement d'expédition",
    zwcx: "L'authenticité de la requête",
    zwcx_placeholder: 'veuillez entrer le produit IMEI',
    cpxx: "informations sur le produit",
    pcy: "assurance qualité / garantie ultra longue durée / fonctionnement sans souci",
    cpxh: "modèle du produit",
    ys: "couleur",
    sbh: "numéro de périphérique",
    ccrq: "date de production",
    sccj: "producteur",
    zjy: 'inspecteur de qualité',
    xsryxm: "nom du vendeur",
    dlsmc: "nom de l'agent",
    gwrk: "entrée du site officiel"
  },
  goodsDetail: {
    price: 'prix',
    number: 'quantité',
    addgoodscat: 'ajouter au panier',
    buynow: 'acheter maintenant'
  },
  safetyHat: {
    cpzxsy: 'page d\'accueil du Centre de produits',
    ss: 'recherche',
    buynow: 'Détails'
  },
  fireEngine: {
    cpzxsy: 'page d\'accueil du Centre de produits',
    ss: 'recherche',
    buynow: 'Détails'
  },
  platForm: {
    cpzxsy: 'page d\'accueil du Centre de produits',
    ss: 'recherche',
    buynow: 'Détails'
  },
  smartElectricity: {
    cpzxsy: 'page d\'accueil du Centre de produits',
    ss: 'recherche',
    buynow: 'Détails'
  },
  smokeDetector: {
    cpzxsy: 'page d\'accueil du Centre de produits',
    ss: 'recherche',
    buynow: 'Détails'
  },
  touringCar: {
    cpzxsy: 'page d\'accueil du Centre de produits',
    ss: 'recherche',
    buynow: 'Détails'
  },
  watch: {
    cpzxsy: 'page d\'accueil du Centre de produits',
    ss: 'recherche',
    buynow: 'Détails'
  },
  waterMeter: {
    cpzxsy: 'page d\'accueil du Centre de produits',
    ss: 'recherche',
    buynow: 'Détails'
  },
  dynamic: {
    home: "accueil",
    xwdt: 'Actualités'
  },
  dynamicDetail: {
    zz: "Auteur"
  },
  dynamicBox: {
    ljxq: 'en savoir plus'
  },
  supplier: {
    qymc: 'nom de l\'entreprise',
    qcrqymc: 'veuillez entrer le nom de l\'entreprise',
    xm: "nom",
    qsrxm: "Veuillez entrer un nom",
    yx: 'boîte aux lettres',
    qsuyx: 'veuillez entrer votre boîte aux lettres',
    dh: 'téléphone',
    qsrdh: "Veuillez entrer un téléphone",
    nr: 'contenu',
    qsrnr: "Veuillez entrer le contenu",
    tj: "soumettre",
    cz: 'réinitialiser',
    qtxzqddhhm: 'veuillez remplir le bon numéro de téléphone',
    qtxzqdyx: 'veuillez remplir la bonne boîte aux lettres',
    qsrqymc: 'veuillez entrer le nom de l\'entreprise',
    qsrsjh: "Veuillez entrer le numéro de téléphone portable"
  },
  agentForm: {
    xm: "nom",
    qsrxm: "Veuillez entrer un nom",
    yx: 'boîte aux lettres',
    qsuyx: 'veuillez entrer votre boîte aux lettres',
    dh: 'téléphone',
    qsrdh: "Veuillez entrer un téléphone",
    nr: 'contenu',
    qsrnr: "Veuillez entrer le contenu",
    tj: "soumettre",
    cz: 'réinitialiser',
    qtxzqddhhm: 'veuillez remplir le bon numéro de téléphone',
    qtxzqdyx: 'veuillez remplir la bonne boîte aux lettres',
    qsrqymc: 'veuillez entrer le nom de l\'entreprise',
    qsrsjh: "Veuillez entrer le numéro de téléphone portable"
  },
  about: {
    dh: 'téléphone',
    yx: 'courrier',
    zbgsdz: 'Adresse de la société siège social',
    zbgsmc:'Siège social nom de la société',
    fbgsmc:"Distribution nom de l'entreprise",
    fbgsdz:'Distribution adresse de la société'
  },
  footerContent: {
    jzhxz: 'Ji - hye download',
    qgzxfwrx: "Hotline nationale",
    gsdzvalue: "Building 11, 4th Floor, Tangtou Nangang Third Industrial Park, Tangtou Community, Shiyan Street, Bao'an District, Shenzhen",
    gsdz: "Adresse de l’entreprise",
    gzwxgzh: "Suivre le compte public WeChat",
    gzwb: "Suivez Weibo",
    wxgzh: "Compte public WeChat",
    APPxz: "Téléchargement APP",
    bq: "Shenzhen mettra la technologie intelligente Co., Ltd. tous droits réservés",
    xz: "Télécharger l’application",
    znsfcp: "Smart Justice Products",
    znxfcp: 'produits intelligents de protection contre l’incendie',
    znafcp: 'roduits de sécurité intelligents',
    znshcp: 'produits de vie intelligents',
    znpj: 'accessoires intelligents',
    znglpt: 'Plateforme de gestion intelligente',
    zhcs: 'ville intelligente',
    zhgc: 'usine intelligente',
    zhxf: 'protection intelligente contre l’incendie',
    zhgd: 'chantier de construction intelligent',
    zhsq: 'Communauté intelligente',
    zhxy: 'campus intelligent',
    jjfa: 'solution',
    cpzxsy: 'Maison des produits',
    bbzx: 'Mettez l’information',
    bbdt: 'Mettre la dynamique',
    hydt: 'Nouvelles de l’industrie',
    bbbk: 'Mettez l’encyclopédie',
    fwzc: 'support de service',
    bbjj: "Mettez l’introduction",
    ryzz: "honneur",
    gybb: 'Mettez l’introduction',
    bbwh: "Mettez l’introduction",
    lxwm: "Contactez-nous",
    hzhb: "partenaire",
    zlxz: 'téléchargement de données',
    rjxz: 'téléchargement de logiciels',
    gysly: 'Message du fournisseur',
    zwcx: 'requête d’authenticité',
    khly: "Message client",
    yhxy:'Accord utilisateur',
    ystk:'Conditions de confidentialité',
    fhtkzc:"Politique de remboursement d'expédition",
  },
  login: {
    bbzn: "Mettez l’intelligence",
    sjh: 'numéro de téléphone portable',
    mm: 'mot de passe',
    zczh: "créer un compte",
    dl: 'login',
    qdl: "Veuillez vous connecter",
    zc: '’inscrire',
    zh: "numéro de compte",
    qsrzh: 'Veuillez saisir le numéro de compte',
    qsrmm: 'Veuillez entrer le mot de passe',
    qrmm: 'Veuillez saisir à nouveau le mot de passe',
    qzcsrmm: "Veuillez saisir votre numéro de téléphone portable",
    qsrsjh: "Veuillez saisir votre numéro de téléphone portable",
    yzm: "captcha",
    qsryzm: "Veuillez saisir le code de vérification",
    cd: "La longueur doit être comprise entre 6-12 bits",
    qtxzqdsjhm: "Veuillez remplir le numéro de téléphone portable correct",
    mmtj: "Le mot de passe doit contenir des chiffres et des lettres, 6-20 chiffres",
    mmbyz: 'incohérence du mot de passe',
    hqyzm: 'Obtenir Captcha',
    get: 'après la gravure'

  },
  logisticsInfo: {
    wlxq: 'Détails logistiques',
    ydhm: 'Numéro de la lettre de voiture',
    wlgs: 'Entreprise de logistique',
    kfdh: 'numéro de service clientèle',
    wlgsgfwz: 'Site officiel des entreprises de logistique',
    zwwlxx: 'Aucune information logistique'
  },
  MyAddress: {
    shdz: 'adresse de livraison',
    tjxdz: "Ajouter une nouvelle adresse",
    mr: "par défaut",
    xg: "modifier",
    sc: 'supprimer',
    shr: 'destinataire',
    shdq: 'Zone de réception',
    xxdz: 'adresse complète',
    sjhm: 'numéro de téléphone portable',
    scdzcg: 'adresse supprimée avec succès',
    xz: 'select',
    dzbm: 'address alias',
    mrdz: 'default address',
    qsrsjrxm: 'Please enter the recipient\'s name',
    qsrdz: 'Please enter address',
    qsrxxdz: 'Please enter full address',
    sjhbnwk: 'mobile phone number cannot be empty',
    sjhgscc: "The mobile phone number format is wrong",
    xgdzcg: 'address changed successfully',
    xinz: 'new',
    tjdzcg: 'address added successfully'
  },
  MyOrder: {
    dd: 'ordre',
    qbdd: 'Toutes les commandes',
    dfk: "en attente",
    dfh: 'À expédier',
    dsh: 'À recevoir',
    ywc: 'terminé',
    my: 'par page',
    tiao: 'article'
  },
  orderDetail: {
    ddxq: 'Détails de la commande',
    ddh: 'numéro de commande',
    cjsj: 'temps de création',
    shrx: 'Informations sur le destinataire',
    shr: 'destinataire',
    sjhm: 'numéro de téléphone portable',
    shdz: 'adresse de livraison',
    cptp: 'Images de produits',
    cpmc: 'nom du produit',
    dj: 'prix unitaire',
    sl: 'quantité',
  },
  pay: {
    shrxx: 'Informations sur le destinataire',
    glshrdz: "Gestion des adresses des destinataires",
    xg: "modifier",
    sc: 'supprimer',
    tjxdz: 'ajouter une nouvelle adresse',
    sqdz: 'collapse address',
    gddz: 'plus d’adresses',
    spxx: "Informations sur le produit",
    fhgwc: 'Retour au panier',
    yh: 'en stock',
    wu: 'en rupture de stock',
    ddbz: 'Notes de commande',
    ts: 'Conseil: Veuillez ne pas remplir d’informations sur le paiement, le reçu',
    tjdd: 'soumettre la commande',
    psz: 'expédier à',
    shdz: 'adresse de livraison',
    shr: 'Destinataire',
    zffs:'Mode de paiement',
    qxzndzffs:'Veuillez choisir votre mode de paiement',
    jnzf:'Paiement sur le territoire',
    jwzf:"Paiement à l'étranger",
    qx:'Cancelación',
    qr:'Annulation'

  },
  payment: {
    ddtjcg: 'Commande soumise avec succès, veuillez payer dès que possible!',
    qnjkwczf: 'Veuillez compléter le paiement dès que possible, sinon la commande sera automatiquement annulée',
    dycsqx: 'La commande a été annulée après un délai d’attente',
    yfje: 'Montant dû',
    yuan: 'yuan',
    qsywxsmzf: 'Veuillez utiliser le code de scan WeChat pour payer',
    zfcghzdtz: 'saut automatique après paiement réussi, si vous voulez sauter, veuillez cliquer sur le bouton pour sauter manuellement',
    cxzf: 'rembourser',
    zfb: 'Alipay',
    wx: 'WeChat',
    zfcg: 'Succès du paiement'
  },
  shopping: {
    tp: 'image',
    spmc: 'Nom commercial',
    dj: 'prix unitaire',
    xj: 'sous-total',
    sl: 'quantité',
    cz: 'opération',
    jxll: 'Continuer à naviguer',
    gong: 'Gong',
    jcp: 'produit à la pièce',
    hj: 'total',
    qjs: 'Aller à la colonie',
    cczjycgsp: 'Cette action va supprimer le produit, voulez-vous continuer?',
    ts: 'indice',
    qr: 'confirmer',
    qx: 'annuler',
    yqxyc: 'non enlevé',
    ycspcg: 'Suppression de l’élément réussie',
    xgslcg: 'nombre de modifications réussies',
    qxznyjsdsp: 'Veuillez sélectionner le produit que vous souhaitez vérifier',
  },
  menu: {
    gwc: 'panier',
    ddzx: 'Centre de commande',
    wddd: 'Mes commandes',
    shdz: 'Adresse de récolte '
  },
  noLogin: {
    ljzf: 'Paiement',
    qxss: 'Tout neuf',
    sl: 'quantité',
    yfje: 'Montant dû',
    gmxx: 'Informations d’achat',
    shxx: 'informations sur le reçu',
    shr: 'destinataire',
    qsrndzwmhywm: 'Veuillez entrer votre nom chinois ou votre nom anglais',
    shdz: 'adresse de livraison',
    qsrndshdz: 'Veuillez saisir votre adresse de récolte',
    lxdh: 'numéro de contact',
    qsrndlxfs: 'Veuillez saisir vos coordonnées',
    yzm: 'captcha',
    qsryzm: 'Veuillez saisir le code de vérification',
    qsrzqdsjh: 'Veuillez saisir le numéro de téléphone portable correct',
    zfb: 'Alipay',
    wx: 'WeChat',
    gsjhybzy: 'Ce numéro de téléphone est déjà pris',
    gsjhmyzcqdl: 'Ce numéro de téléphone portable est enregistré, veuillez vous connecter'
  },
  home: {
    tjcp: 'Produits recommandés',
    ckgd: 'voir plus',
    jjfa: 'solution',
    bbznglpt: 'mettre la plate-forme de gestion intelligente',
    xmal: 'cas de projet',
    zlhzhb: 'partenaire stratégique',
    yljh: 'liens',
    bbscrk: 'Mettez l’entrée du centre commercial',
    bbptdl: 'log the platform in',
    gysrk: 'entrée du fournisseur',
    hzhbrk: 'portail des partenaires',
    zsjmrk: 'entrée d’investissement',
  },
  orderBox: {
    ddh: 'numéro de commande',
    cjsj: ' temps de création ',
    ddxq: 'Détails de la commande',
    dzf: 'À payer',
    sdgb: 'arrêt manuel',
    qxdd: 'annuler la commande',
    wzf: 'non rémunéré',
    chgb: 'timeout off',
    zfsb: 'échec du paiement',
    qzf: 'Allez payer',
    ckcg: 'sortant réussi',
    ywc: 'terminé',
    kcwl: 'Voir la logistique',
    qrsh: 'Confirmer la réception',
    qxzqxddyy: 'Veuillez sélectionner un motif d’annulation',
    qx: 'annuler',
    qr: 'confirmer',
    qrgb: 'confirmer la fermeture',
    qxddcg: 'Commande annulée réussie',
    nqryshm: ' Êtes-vous sûr de vouloir prendre la marchandise ? ',
    ts: 'indice',
    yqxsh: 'Annulé',
    wldt:'Dynamique logistique',
    sqsh:'Après la demande de vente',
    shfw:'Service après - vente',
    qxzshfw:'Veuillez sélectionner le type après - vente',
    gzjj:'Résolution des défauts',
    hh:'Échanges',
    thtk:'Remboursement des retours',
    tkbth:'Remboursement sans retour'
  }

}
