import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import login from "../views/login.vue";
import menu from "../views/user/menu.vue";
import zh from '../i18n/routes/route_zh'
import ar from '../i18n/routes/route_ar'
import be from '../i18n/routes/route_be'
import cn from '../i18n/routes/route_cn'
import de from '../i18n/routes/route_de'
import en from '../i18n/routes/route_en'
import es from '../i18n/routes/route_es'
import fr from '../i18n/routes/route_fr'
import ru from '../i18n/routes/route_ru'
Vue.use(VueRouter);
// 解决路由重复问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err);
};
const langs = [{ parent: 'zh' }, { parent: 'en' }, { parent: 'de' }, { parent: 'ar' }, { parent: 'es' }, { parent: 'fr' }, { parent: 'ru' }]
// 遍历langs
// langs.forEach((item) => {
//   // 遍历每个语言的子路由
//   item.children = [zh, en, de, ar, es, fr, ru].map((route) => {
//     // 深拷贝
//     const newRoute = JSON.parse(JSON.stringify(route))
//     // 设置path
//     newRoute.path = `/${item.parent}${route.path}`
//     // 设置name
//     newRoute.name = `${item.parent}${route.name}`
//     // 设置meta
//     newRoute.meta = {
//       ...newRoute.meta,
//       lang: item.parent
//     }
//     return newRoute
//   })
// })
console.log(langs)
const routes = [
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: '/',
    redirect: '/product'
  },
  {
    path: '/home',
    redirect: '/product'
  },
  {
    path: "/menu", // 主页
    component: menu,
    children: [
      {
        path: "/MyOrder", //案例详情
        name: "MyOrder",
        component: () => import("../views/orderCenter/MyOrder.vue"),
      },
      {
        path: "/MyAddress", //收货地址
        name: "MyAddress",
        component: () => import("../views/orderCenter/MyAddress.vue"),
      },
      {
        path: "/shopping", //购物车
        name: "shopping",
        component: () => import("../views/orderCenter/shopping.vue"),
      },
      {
        path: "/pay", //订单页
        name: "pay",
        component: () => import("../views/orderCenter/pay.vue"),
      },
      {
        path: "/payment", //付款
        name: "payment",
        component: () => import("../views/orderCenter/payment.vue"),
      },
      {
        path: "/orderDetail", //付款
        name: "orderDetail",
        component: () => import("../views/orderCenter/orderDetail.vue"),
      },
      {
        path: "/logisticsInfo", //物流信息
        name: "logisticsInfo",
        component: () => import("../views/orderCenter/logisticsInfo.vue"),
      },
    ],
  },
  {
    path: '/watch',
    name: 'watch',
    redirect: '/product/watch',
    component: () => import('../views/products/watch.vue'),
    meta: {
      title: '定位手表'
    }
  },
  {
    path: '/platForm',
    redirect: '/product/platForm',
    name: 'platForm',
    component: () => import('../views/products/platForm.vue'),
    meta: {
      title: '智能平台'
    }
  }, {
    path: '/safetyHat',
    redirect: '/product/safetyHat',
    name: 'safetyHat',
    component: () => import('../views/products/safetyHat.vue'),
    meta: {
      title: '智能安全帽'
    }
  }, {
    path: '/smartElectricity',
    redirect: '/product/smartElectricity',
    name: 'smartElectricity',
    component: () => import('../views/products/smartElectricity.vue'),
    meta: {
      title: '智慧用电'
    }
  }, {
    path: '/smokeDetector',
    redirect: '/product/smokeDetector',
    name: 'smokeDetector',
    component: () => import('../views/products/smokeDetector.vue'),
    meta: {
      title: '烟雾报警器'
    }
  }, {
    path: '/touringCar',
    redirect: '/product/touringCar',
    name: 'touringCar',
    component: () => import('../views/products/touringCar.vue'),
    meta: {
      title: '智能房车'
    }
  }, {
    path: '/waterMeter',
    redirect: '/product/waterMeter',
    name: 'waterMeter',
    component: () => import('../views/products/waterMeter.vue'),
    meta: {
      title: '智能水表'
    }
  },
  {
    path: '/fireEngine',
    redirect: '/product/fireEngine',
    name: 'fireEngine',
    component: () => import('../views/products/fireEngine.vue'),
    meta: {
      title: '消防车辆'
    }
  },
  {
    path: '/monitor',
    redirect: '/product/monitor',
    name: 'monitor',
    component: () => import('../views/products/monitor.vue'),
    meta: {
      title: '监控设备'
    }
  },
  {
    path: "/",
    name: "index",
    component: () => import('../views/index.vue'),
    meta: {
      title:
        "把把智能科技有限公司—智能安防，UWB定位系统、智能安全帽、4G通讯模组及物联网平台供应商",
      content: {
        keywords:
          "智能安全帽,智能安全帽生产厂家,智慧安全帽,智能烟感,智慧烟感,智能烟感厂家,独立烟感报警器,无线烟感,UWB高精度定位系统,户外电源,把把智能,深圳把把智能科技有限公司,把把智能烟感,bbjt",
        description:
          "深圳把把智能科技有限公司生产的把把智能安全帽、智能烟感、UWB定位系统、户外电源等产品功能完备、可靠性高，续航强劲，先后通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证等18项多个国家和地区的专业认证。",
      },
    },
    children: [
      {
        path: '/',
        redirect: 'product',
      },
      {
        path: '/appdownload',
        name: 'appdownload',
        component: () => import('../views/appdownload.vue'),
      },
      {
        path: "product",
        name: "home",
        component: () => import("../views/product.vue"),
        meta: {
          title:
            "把把智能科技有限公司—智能安防，UWB定位系统、智能安全帽、4G通讯模组及物联网平台供应商",
          content: {
            keywords:
              "智能安全帽,智能安全帽生产厂家,智慧安全帽,智能烟感,智慧烟感,智能烟感厂家,独立烟感报警器,无线烟感,UWB高精度定位系统,户外电源,把把智能,深圳把把智能科技有限公司,把把智能烟感,bbjt",
            description:
              "深圳把把智能科技有限公司生产的把把智能安全帽、智能烟感、UWB定位系统、户外电源等产品功能完备、可靠性高，续航强劲，先后通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证等18项多个国家和地区的专业认证。",
          },
        },
        children: [
          {
            path: '/',
            redirect: 'index'
          },
          {
            path: 'index',
            name: 'index',
            component: () => import('../views/products/index.vue'),
            meta: {
              title: '产品中心首页'
            }
          },
          {
            path: 'watch',
            name: 'watch',
            component: () => import('../views/products/watch.vue'),
            meta: {
              title: '定位手表'
            }
          },
          {
            path: 'platForm',
            name: 'platForm',
            component: () => import('../views/products/platForm.vue'),
            meta: {
              title: '智能平台'
            }
          }, {
            path: 'safetyHat',
            name: 'safetyHat',
            component: () => import('../views/products/safetyHat.vue'),
            meta: {
              title: '智能安全帽'
            }
          }, {
            path: 'smartElectricity',
            name: 'smartElectricity',
            component: () => import('../views/products/smartElectricity.vue'),
            meta: {
              title: '智慧用电'
            }
          }, {
            path: 'smokeDetector',
            name: 'smokeDetector',
            component: () => import('../views/products/smokeDetector.vue'),
            meta: {
              title: '烟雾报警器'
            }
          }, {
            path: 'touringCar',
            name: 'touringCar',
            component: () => import('../views/products/touringCar.vue'),
            meta: {
              title: '智能房车'
            }
          }, {
            path: 'waterMeter',
            name: 'waterMeter',
            component: () => import('../views/products/waterMeter.vue'),
            meta: {
              title: '智能水表'
            }
          },
          {
            path: 'fireEngine',
            name: 'fireEngine',
            component: () => import('../views/products/fireEngine.vue'),
            meta: {
              title: '消防车辆'
            }
          },
          {
            path: 'monitor',
            name: 'monitor',
            component: () => import('../views/products/monitor.vue'),
            meta: {
              title: '监控设备'
            }
          },
        ]
      },
      {
        path: "agent",
        name: "agent",
        component: () => import("../views/about/agent.vue"),
        meta: {
          title:
            "把把智能科技有限公司—智能安防，UWB定位系统、智能安全帽、4G通讯模组及物联网平台供应商",
          content: {
            keywords:
              "把把智能简介,把把智能企业简介,把把智能企业文化,把把智能荣誉资质,把把智能合作伙伴,把把智能联系方式,把把智能电话,把把智能联系电话,深圳把把智能科技有限公司,bbjt",
            description:
              "深圳把把智能科技有限公司成立于2019年，总部位于深圳市宝安区，是国内安防产品制造业为数不多的集自主研发、生产体系、解决方案于一体的国家高新技术企业，研发团队由国内外知名专家学者和业内资深工程师组成，累计获得各项技术专利68项，产品体系涵盖智慧城市、智慧工厂、智慧消防、智慧工地、智慧社区、智慧校园等多个应用场景。",
          },
        },
      },
      {
        path: "appdown",
        name: "appdown",
        component: () => import("../views/about/appdown.vue"),
        meta: {
          title:
              "把把智能科技有限公司—智能安防，UWB定位系统、智能安全帽、4G通讯模组及物联网平台供应商",
          content: {
            keywords:
                "把把智能简介,把把智能企业简介,把把智能企业文化,把把智能荣誉资质,把把智能合作伙伴,把把智能联系方式,把把智能电话,把把智能联系电话,深圳把把智能科技有限公司,bbjt",
            description:
                "深圳把把智能科技有限公司成立于2019年，总部位于深圳市宝安区，是国内安防产品制造业为数不多的集自主研发、生产体系、解决方案于一体的国家高新技术企业，研发团队由国内外知名专家学者和业内资深工程师组成，累计获得各项技术专利68项，产品体系涵盖智慧城市、智慧工厂、智慧消防、智慧工地、智慧社区、智慧校园等多个应用场景。",
          },
        },
      },
      {
        path: "about",
        name: "about",
        component: () => import("../views/about/about.vue"),
        meta: {
          title: "把把智能——关于把把智能",
          content: {
            keywords:
              "把把智能简介,把把智能企业简介,把把智能企业文化,把把智能荣誉资质,把把智能合作伙伴,把把智能联系方式,把把智能电话,把把智能联系电话,深圳把把智能科技有限公司,bbjt",
            description:
              "深圳把把智能科技有限公司成立于2019年，总部位于深圳市宝安区，是国内安防产品制造业为数不多的集自主研发、生产体系、解决方案于一体的国家高新技术企业，研发团队由国内外知名专家学者和业内资深工程师组成，累计获得各项技术专利68项，产品体系涵盖智慧城市、智慧工厂、智慧消防、智慧工地、智慧社区、智慧校园等多个应用场景。",
          },
        },
      },
      {
        path: "solution",
        name: "solution",
        component: () => import("../views/solution/solution.vue"),
        meta: {
          title: "把把智能科技—智能安防，UWB定位系统、智能安全帽、4G通讯模组及物联网平台供应商——智能一体化解决方案",
          content: {
            keywords:
              "智慧城市,智慧工厂,智慧消防,智慧工地,智慧社区,智慧校园,把把智能,把把智能解决方案,深圳把把智能科技有限公司,bbjt",
            description:
              "把把智能精心打造智慧城市、智慧工厂、智慧消防、智慧工地、智慧社区、智慧校园等应用场景解决方案，为我国各行业安全生产助力赋能。并可根据行业特别需求提供相应的深度定制解决方案，坚持以技术研发为中心，以自主创新打造品牌核心竞争力。",
          },
        },
      },
      {
        path: "solutionDetail",
        name: "solutionDetail",
        component: () => import("../views/solution/solutionDetail.vue"),
      },
      {
        path: "case",
        name: "case",
        component: () => import("../views/case.vue"),
        meta: {
          title: "把把智能科技——项目案例",
          content: {
            keywords:
              "把把智能科技项目案例,把把智能工程案例,把把智能解决方案,把把智能,深圳把把智能科技有限公司,bbjt",
            description:
              "把把智能科技斥巨资引进国内外顶级的现代化生产线和检测设备，并先后通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证等18项多个国家和地区的专业认证，卓越的品质、过硬的质量和完善的售后，让把把智能赢得了中交集团、中铁集团、中国建筑、中国安能、中国风电等合作伙伴的高度认可和一致青睐，营销网络覆盖亚非欧多个国家和地区。",
          },
        },
      },
      {
        path: "dynamic",
        name: "dynamic",
        component: () => import("../views/dynamic.vue"),
        meta: {
          title: "把把智能科技——把把智能资讯",
          content: {
            keywords:
              "把把智能动态、把把智能资讯、把把智能最新动态、把把智能新闻、把把智能最新政策、深圳把把智能科技有限公司,bbjt",
            description:
              "把把智能将始终如一地坚持自主研发路线、贯彻自主创新理念，秉承创造社会价值、实现自我增值的经营理念，以满足客户需求为己任、以开拓、创新、创造为宗旨，以诚信、专业、拼搏、感恩的企业价值观成就行业标杆，为我国各行业安全生产和人民幸福生活保驾护航。",
          },
        },
      },
      {
        path: "dynamicDetail", //文章详情
        name: "dynamicDetail",
        component: () => import("../views/dynamicDetail.vue"),
      },
      {
        path: "video",
        name: "video",
        component: () => import("../views/video/video.vue"),
        meta: {
          title: "把把智能——在线视频",
          content: {
            keywords:
              "把把智能在线视频,把把智能视频,智能安全帽视频,把把智能直播,把把智能在线直播,把把智能视频攻略,把把智能教程,bbjt",
            description:
              "把把智能在线视频栏目发布由把把智能官方制作或由用户自行上传的各类精彩视频，把把智能也将不定期进行直播，精彩呈现，尽在把把智能视频。",
          },
        },
      },
      {
        path: "serve",
        name: "serve",
        component: () => import("../views/serve/serve.vue"),
        meta: {
          title: "把把智能——服务支持",
          content: {
            keywords:
              "把把智能资料下载,把把智能产品查询,把把智能产品真伪查询,把把智能软件下载,深圳把把智能科技有限公司,bbjt",
            description:
              "把把智能产品具有超小尺寸、超低功耗、易于集成、长期使用性和超高稳定性等特点。在物联网、智能硬件、saas智能大数据、人工智能、融合通信、软件技术等",
          },
        },
      },
      {
        path: "homes", //我的
        name: "homes",
        component: () => import("../views/forum/homes.vue"),
        meta: {
          title: "把把智能行业交流论坛",
          content: {
            keywords:
              "把把智能论坛,智能安全帽行业论坛,智能安全帽论坛,把把智能论坛,把把智能在线留言,把把智能意见建议,把把智能投诉建议,把把智能投诉,bbjt",
            description:
              "把把智能行业交流论坛旨在为业内提供一个公平公正公开的行业交流平台，用户在交流过程中请务必遵纪守法、文明礼貌，对把把智能发展相关的意见建议、投诉均可在此留言。",
          },
        },
      },
      {
        path: "my", //我的
        name: "my",
        component: () => import("../views/forum/my.vue"),
      },
      {
        path: "article", //发表文章
        name: "article",
        component: () => import("../views/forum/article.vue"),
      },
      {
        path: "srticleDetail", //文章详情
        name: "srticleDetail",
        component: () => import("../views/forum/srticleDetail.vue"),
      },
      {
        path: "goodsDetail", //商品详情
        name: "goodsDetail",
        component: () => import("../views/goodsDetail.vue"),
      },
      {
        path: "casesDetail", //案例详情
        name: "casesDetail",
        component: () => import("../views/casesDetail.vue"),
      },
      {
        path: "noLogin", //物流信息
        name: "noLogin",
        component: () => import("../views/noLogin.vue"),
      },
    ]
  },
  { ...zh }, { ...ar }, { ...be }, { ...cn }, { ...de }, { ...en }, { ...es }, { ...fr }, { ...ru }

];
// var routers = []
// for (var item of langs) {
//   routers.push({
//     path: '/' + item.parent,
//     name: item.parent,
//     component: () => import('../views/' + item.parent + '/index.vue'),
//     meta: {
//       title: '把把智能_bbjt-智能安全帽厂家-工地智能安全帽',
//       keywords:
//         "手持终端、智能安全挂钩、智能安全帽、安全帽充电柜、烟雾报警器、燃气报警器、报警按钮、联动开关、声光报警器、北斗定位器、防拆腕表、定位手表、通讯网关、智能空气开关,bbjt",
//       description:
//         "深圳把把智能科技有限公司主要从事4G通信技术研发和终端产品制造，先后取得各项专利60多项，并通过IOS9001质量管理体系认证、中国3C认证、欧盟CE认证、美国FCC认证、CLEx防爆认证等18项多个国家和地区的专业认证，打造自主品牌bbjt, 主要产品包括手持终端、智能安全帽、烟雾报警器、燃气报警器、定位手表、通讯网关、智能空气开关等智能终端产品，应用于城市管理、物业、电力、煤矿、基建、军区、司法、校园、环保等行业。"
//     },
//     children: [
//       ...routes
//     ]
//   })
// }
console.log(routes)
const router = new VueRouter({
  // mode: 'hash',
  mode: "history", // 预渲染一定要模式改成history
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.content) {
    let head = document.getElementsByTagName("head");
    let meta = document.createElement("meta");
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", to.meta.content.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
  const token = localStorage.getItem("token");
  if (
    to.name == "MyAddress" ||
    to.name == "shopping" ||
    to.name == "pay" ||
    to.name == "menu" ||
    to.name == "payment" ||
    to.name == "MyOrder" ||
    to.name == "article"
  ) {
    if (token) {
      next();
      return;
    }
    next("/login");
    return;
  }
  next();
});

export default router;
