import axios from 'axios'
import md5 from 'js-md5';
const translate = function (str) {
  var salt = new Date().getTime()
  var appid = '20230414001641309'
  var sign = md5(appid + str + salt + 'dyy5NyHTndnBmYvCWbxy').toLowerCase()
  axios.post('/api', { q: str, from: 'auto', to: localStorage.getItem('lang'), appid: appid, salt: salt, sign }, {}).then(res => {
    console.log(res)
  })
}
export default translate