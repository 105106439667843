import Vue from 'vue'
// 使用插件
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
if (!localStorage.getItem('lang')) {
  console.log(document.location.href, '语言环境问题')
  var str = document.location.href.slice(23, 25)
  var arr = ['ar', 'be', 'cn', 'de', 'en', 'es', 'fr', 'ru', 'zh']
  for (var item of arr) {
    if (str != '' && str.indexOf(item) != -1) {
      console.log(item)
      // localStorage.setItem('lang', item)
    }
  }
}
const i18n = {
  locale: localStorage.getItem('lang') || 'zh', //使用语言
  fallbackLocale: 'zh', //缺省语言
  // 中文 英文 俄文 德文 法文 阿拉伯文 西班牙文
  messages: {
    zh: require('./language/local_zh'), // 中文
    en: require('./language/local_en'), // 英语
    es: require('./language/local_es'), // 西班牙语
    ru: require('./language/local_ru'), //俄文
    de: require('./language/local_de'), //德文
    fr: require('./language/local_fr'), //法文
    ar: require('./language/local_ar'), //阿拉伯文
    nl: require('./language/local_nl'), //荷兰文
    pt: require('./language/local_pt')  //葡萄牙 
  }
}

export default i18n
