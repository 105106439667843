onload()

function pcRem (){
    // let winWidth = document.documentElement.offsetWidth || document.body.offsetWidth
    let clientWidth = document.documentElement.clientWidth;
    document.getElementsByTagName("body")[0].style.backgroundColor = "none";
    document.getElementsByTagName("body")[0].style.position = "static";
    document.getElementsByTagName("body")[0].style.width = clientWidth + 'px';
    document.getElementsByTagName("body")[0].style.margin = 'auto';

    let ohtml = document.getElementsByTagName('html')[0]
    clientWidth = clientWidth < 1366 ? 1366 : clientWidth
    ohtml.style.fontSize = 100 * clientWidth / 1920 + 'px'
}

function mobileRem (){
    let clientWidth = document.documentElement.clientWidth;
    document.getElementsByTagName("body")[0].style.position = "relative";
    document.getElementsByTagName("body")[0].style.width = clientWidth + 'px';
    document.getElementsByTagName("body")[0].style.margin = 'auto';
    let ohtml = document.getElementsByTagName('html')[0]
    ohtml.style.fontSize = 100 * clientWidth / 750 + 'px'

    // document.ready = () => {
    //     document.querySelector('body').style.height = document.querySelector(window).offsetHeight
    // }
    // $(document).ready(function () { $('body').css({'height':$(window).height()})});
}

function onload(){
    let winWidth = document.documentElement.offsetWidth || document.body.offsetWidth
    if( winWidth >= 750){
        pcRem()
    }else{
        mobileRem()
    }
    window.addEventListener('resize' , () => {
        let winWidth1 = document.documentElement.offsetWidth || document.body.offsetWidth
        console.log(winWidth1);
        if( winWidth1 >= 750){
            pcRem()
        }else{
            mobileRem()
        }
    })

}