// 西班牙语
module.exports = {
  banner: {
    ljlj: 'Conocer los detalles',
  },
  header: {
    bbzn: 'BaBa Inteligencia',
    hyn: 'bienvenido!',
    wddd: 'Pedidos',
    gwc: 'Carrito',
    ptdl: 'inicio de sesión de la plataforma',
    qdl: 'Iniciar sesión',
    tcdl: 'salir de inicio de sesión',
    dhl: 'barra de navegación'
  },
  nav: {
    bbwh: "Pon la cultura",
    home: "página de inicio",
    flag: "[quién] el nombre es [qué]",
    zhcs: 'ciudad inteligente',
    zhgc: 'smart factory',
    zhxf: 'smart Fire protection',
    zhgd: 'sitio de construcción inteligente',
    zhsq: 'comunidad inteligente',
    zhxy: 'campus inteligente',
    bbdt: 'dinámico',
    zwcx: "consulta de autenticidad",
    zlxz: "descarga de datos",
    rjxz: "descarga de software",
    gysly: "mensaje del proveedor",
    khly: "mensaje del cliente",
    bbjj: "briefing",
    ryzz: "calificación honorífica",
    lxwm: "contáctanos",
    hydt: 'dinámica de la industria',
    bbbk: 'enciclopedia',
    cpzx: 'productos',
    jjfa: 'Soluciones',
    xmal: 'Proyectos',
    bbzx: 'Información',
    fwzc: 'Servicios',
    zsdl: 'Inversores',
    gybb: 'Sobre nosotros',
    zxsp: 'Vídeo',
    hylt: 'BBS',
    yhxy:'Protocolo de usuario',
    ystk:'Cláusula de privacidad',
    fhtkzc:'Política de reembolso de envío',
  },
  product: {
    ljgm: 'Detalles',
    cpzxsy: 'Productos',
    znaqm: 'Smart casco',
    ywbjq: 'Alarma de humo',
    dwsb: 'Posicionamiento Reloj',
    zhyd: 'La sabiduría de la electricidad',
    znsb: 'Medidor inteligente',
    znpt: 'Plataforma inteligente',
    yyfc: 'todoterreno',
    xfcl: 'vehículo de bomberos',
    ss: 'búsqueda'
  },
  footer: {

  },
  solution: {
    ckgd: 'ver más'
  },
  common: {
    sy: 'página de inicio',


  },
  breadcrumb: {
    xwdt: 'noticias dinámicas',
    bbdt: 'dinámica',
    hydt: 'dinámica de la industria',
    bbbk: 'enciclopedia',
    ljxq: 'conoce los detalles'
  },
  serve: {
    fwzc: 'soporte de servicio',
    zlxz: 'descarga de datos',
    rjxz: 'descarga de software',
    gysly: 'mensaje del proveedor',
    yhxy:'Protocolo de usuario',
    ystk:'Cláusula de privacidad',
    fhtkzc:'Política de reembolso de envío',
    zwcx: 'consulta de autenticidad',
    zwcx_placeholder: 'introduzca el producto imei\' por favor',
    cpxx: "información del producto",
    pcy: "garantía de calidad / garantía de calidad súper larga / operación sin preocupaciones",
    cpxh: "modelo de producto",
    ys: "color",
    sbh: "número de equipo",
    ccrq: "fecha de producción",
    sccj: "fabricante",
    zjy: 'inspector de calidad',
    xsryxm: "nombre del personal de ventas",
    dlsmc: "nombre del agente",
    gwrk: "entrada al sitio web oficial"
  },
  goodsDetail: {
    price: 'precio',
    number: 'número',
    addggoodscat: 'Únete al carrito de la compra',
    buynow: 'compra ahora'
  },
  safetyHat: {
    cpzxsy: 'página de inicio del Centro de productos',
    ss: 'búsqueda',
    buynow: 'Detalles'
  },
  fireEngine: {
    cpzxsy: 'página de inicio del Centro de productos',
    ss: 'búsqueda',
    buynow: 'Detalles'
  },
  platForm: {
    cpzxsy: 'página de inicio del Centro de productos',
    ss: 'búsqueda',
    buynow: 'Detalles'
  },
  smartElectricity: {
    cpzxsy: 'página de inicio del Centro de productos',
    ss: 'búsqueda',
    buynow: 'Detalles'
  },
  smokeDetector: {
    cpzxsy: 'página de inicio del Centro de productos',
    ss: 'búsqueda',
    buynow: 'Detalles'
  },
  touringCar: {
    cpzxsy: 'página de inicio del Centro de productos',
    ss: 'búsqueda',
    buynow: 'Detalles'
  },
  watch: {
    cpzxsy: 'página de inicio del Centro de productos',
    ss: 'búsqueda',
    buynow: 'Detalles'
  },
  waterMeter: {
    cpzxsy: 'página de inicio del Centro de productos',
    ss: 'búsqueda',
    buynow: 'Detalles'
  },
  dynamic: {
    home: "página de inicio",
    xwdt: 'noticias dinámicas'
  },
  dynamicDetail: {
    zz: "Autor"
  },
  dynamicBox: {
    ljxq: 'conoce los detalles'
  },
  supplier: {
    qymc: 'nombre de la empresa',
    qcrqymc: 'por favor, introduzca el nombre de la empresa',
    xm: "nombre",
    qsrxm: "por favor, introduzca su nombre",
    yx: 'por favor, introduzca su nombre',
    qsuyx: 'por favor, introduzca el buzón',
    dh: 'teléfono',
    qsrdh: "por favor, introduzca el teléfono",
    nr: 'contenido',
    qsrnr: "por favor, introduzca el contenido",
    tj: "enviar",
    cz: 'restart',
    qtxzqddhhm: 'por favor, rellene el número de teléfono correcto',
    qtxzqdyx: 'por favor, rellene el buzón correcto',
    qsrqymc: 'por favor, introduzca el nombre de la empresa',
    qsrsjh: "por favor, introduzca el número de teléfono móvil",
  },
  agentForm: {
    xm: "nombre",
    qsrxm: "por favor, introduzca su nombre",
    yx: 'por favor, introduzca su nombre',
    qsuyx: 'por favor, introduzca el buzón',
    dh: 'teléfono',
    qsrdh: "por favor, introduzca el teléfono",
    nr: 'contenido',
    qsrnr: "por favor, introduzca el contenido",
    tj: "enviar",
    cz: 'restart',
    qtxzqddhhm: 'por favor, rellene el número de teléfono correcto',
    qtxzqdyx: 'por favor, rellene el buzón correcto',
    qsrqymc: 'por favor, introduzca el nombre de la empresa',
    qsrsjh: "por favor, introduzca el número de teléfono móvil",
  },
  about: {
    dh: 'teléfono',
    yx: 'correo',
    zbgsdz: 'Dirección de la empresa de la Sede',
    zbgsmc:'Nombre de la empresa con sede',
    fbgsmc:'Nombre de la empresa de distribución',
    fbgsdz:'Dirección de la empresa de distribución'
  },
  footerContent: {
    jzhxz: 'Jia Zhihui download',
    qgzxfwrx: "Línea directa nacional",
    gsdzvalue: "Building 11, 4th Floor, Tangtou Nangang Third Industrial Park, Tangtou Community, Shiyan Street, Bao'an District, Shenzhen",
    gsdz: "Dirección de la empresa",
    gzwxgzh: "Siga la cuenta pública de WeChat",
    gzwb: "Sigue a Weibo",
    wxgzh: "Cuenta pública de WeChat",
    APPxz: "APP Download",
    bq: "Shenzhen pondrá la tecnología inteligente Co., Ltd. todos los derechos reservados",
    xz: "Descargar la aplicación",
    znsfcp: "Productos de justicia inteligente",
    znxfcp: 'productos inteligentes de protección contra incendios',
    znafcp: 'productos de seguridad inteligentes',
    znshcp: 'productos de vida inteligente',
    znpj: 'accesorios inteligentes',
    znglpt: 'Plataforma de gestión inteligente',
    zhcs: 'ciudad inteligente',
    zhgc: 'fábrica inteligente',
    zhxf: 'protección inteligente contra incendios',
    zhgd: 'Obra de construcción inteligente',
    zhsq: 'comunidad inteligente',
    zhxy: 'campus inteligente',
    jjfa: 'solución',
    cpzxsy: 'Centro de productos',
    bbzx: 'Poner la información',
    bbdt: 'Poner la dinámica',
    hydt: 'Noticias de la industria',
    bbbk: 'Pon la enciclopedia',
    fwzc: 'soporte de servicio',
    bbjj: "Pon la introducción",
    ryzz: "honor",
    gybb: 'Acerca del mango',
    bbwh: "Poner la cultura",
    lxwm: "Contáctanos",
    hzhb: "socio",
    zlxz: 'descarga de datos',
    rjxz: 'descarga de software',
    gysly: 'Mensaje del proveedor',
    zwcx: 'consulta de autenticidad',
    khly: "mensaje del cliente",
    yhxy:'Protocolo de usuario',
    ystk:'Cláusula de privacidad',
    fhtkzc:'Política de reembolso de envío',
  },
  login: {
    bbzn: "Pon la inteligencia",
    sjh: 'número de teléfono móvil',
    mm: 'contraseña',
    zczh: "registrar una cuenta",
    dl: 'inicio de sesión',
    qdl: "Por favor, inicie sesión",
    zc: 'Registrarse',
    zh: "número de cuenta",
    qsrzh: 'Por favor, introduzca el número de cuenta',
    qsrmm: 'Por favor, introduzca la contraseña',
    qrmm: 'confirmar contraseña',
    qzcsrmm: "Por favor, introduzca la contraseña de nuevo",
    qsrsjh: "Por favor, introduzca su número de teléfono móvil",
    yzm: "captcha",
    qsryzm: "Por favor, introduzca el código de verificación",
    cd: "La longitud debe estar entre 6-12 bits",
    qtxzqdsjhm: "Por favor, rellene el número de teléfono móvil correcto",
    mmtj: "La contraseña debe contener números y letras, 6-20 dígitos",
    mmbyz: 'inconsistencia de contraseña',
    hqyzm: "Obtener Captcha",
    Obtener: 'después de volver a buscar'

  },
  logisticsInfo: {
    wlxq: 'detalles logísticos',
    ydhm: 'Número de albarán',
    wlgs: 'Empresa de logística',
    kfdh: 'número de servicio de atención al cliente',
    wlgsgfwz: 'Sitio web oficial de las empresas de logística',
    zwwlxx: 'No hay información logística'
  },
  MyAddress: {
    shdz: 'dirección de entrega',
    tjxdz: "Agregar nueva dirección",
    mr: "por defecto",
    xg: "modificar",
    sc: 'suprimir',
    shr: 'Destinatario',
    shdq: 'Área de recepción',
    xxdz: 'dirección completa',
    sjhm: 'número de móvil',
    scdzcg: 'Dirección eliminada correctamente',
    xz: 'seleccionar',
    dzbm: 'alias de dirección',
    mrdz: 'dirección predeterminada',
    qsrsjrxm: 'Por favor, introduzca el nombre del destinatario',
    qsrdz: 'Por favor, introduzca la dirección',
    qsrxxdz: 'Por favor, introduzca la dirección completa',
    sjhbnwk: 'el número de teléfono móvil no puede estar vacío',
    sjhgscc: "El formato del número de teléfono móvil es incorrecto",
    xgdzcg: 'dirección cambiada correctamente',
    xinz: 'nuevo',
    tjdzcg: 'Dirección agregada correctamente'
  },
  MyOrder: {
    dd: 'orden',
    qbdd: 'Todos los pedidos',
    dfk: "pendiente",
    dfh: 'Para ser enviado',
    dsh: 'Pendiente de recepción',
    ywc: 'completado',
    my: 'por página',
    tiao: 'artículo'

  },
  orderDetail: {
    ddxq: 'Detalles del pedido',
    ddh: 'número de pedido',
    cjsj: 'tiempo de creación',
    shrx: 'Información del destinatario',
    shr: 'Destinatario',
    sjhm: 'número de móvil',
    shdz: 'dirección de entrega',
    cptp: 'Imágenes de productos',
    cpmc: 'nombre del producto',
    dj: 'precio unitario',
    sl: 'cantidad'

  },
  pay: {
    shrxx: 'Información del destinatario',
    glshrdz: "Gestión de direcciones de destinatarios",
    xg: "modificar",
    sc: 'suprimir',
    tjxdz: 'añadir nueva dirección',
    sqdz: 'dirección de colapso',
    gddz: 'más direcciones',
    spxx: "Información del producto",
    fhgwc: 'Volver al carrito',
    yh: 'en stock',
    wu: 'agotado',
    ddbz: 'Notas de pedido',
    ts: 'Consejo: Por favor, no rellene la información sobre el pago, recibo',
    tjdd: 'enviar pedido',
    psz: 'enviar a',
    shdz: 'dirección de entrega',
    shr: 'Consignatario',
    zffs:'Método de pago',
    qxzndzffs:'Por favor, elija su método de pago.',
    jnzf:'Pagos nacionales',
    jwzf:'Pagos en el extranjero',
    qx:'Cancelación',
    qr:'Confirmación'
  },
  payment: {
    ddtjcg: '¡Pedido enviado con éxito, por favor pague lo antes posible!',
    qnjkwczf: "Complete el pago lo antes posible, de lo contrario el pedido se cancelará automáticamente",
    dycsqx: 'el pedido ha sido cancelado después del tiempo de espera',
    yfje: 'Importe adeudado',
    yuan: 'yuan',
    qsywxsmzf: 'Utilice el código de escaneo de WeChat para pagar',
    zfcghzdtz: 'Salto automático después de un pago exitoso, si desea saltar, haga clic en el botón para saltar manualmente',
    cxzf: 'reembolso',
    zfb: 'Alipay',
    wx: 'WeChat',
    zfcg: 'Éxito de pago'
  },
  shopping: {
    tp: 'imagen',
    spmc: 'Nombre comercial',
    dj: 'precio unitario',
    xj: 'subtotal',
    sl: 'cantidad',
    cz: "operación",
    jxll: 'Continuar navegando',
    gong: 'Gong',
    jcp: 'producto a pieza',
    hj: 'total',
    qjs: 'Ir al asentamiento',
    cczjycgsp: 'Esta acción eliminará el producto, ¿desea continuar?',
    ts: 'pista',
    qr: 'confirmar',
    qx: 'cancelar',
    yqxyc: 'sin eliminar',
    ycspcg: 'Eliminación del artículo exitosa',
    xgslcg: 'número de modificaciones exitosas',
    qxznyjsdsp: 'Seleccione el producto que desea verificar',
  },
  menu: {
    gwc: 'carrito de compras',
    ddzx: 'Centro de pedidos',
    wddd: 'Mis órdenes',
    shdz: 'dirección de cosecha'
  },
  noLogin: {
    ljzf: 'Pago',
    qxss: 'Nuevo',
    sl: 'cantidad',
    yfje: 'Importe adeudado',
    gmxx: 'Información de compra',
    shxx: 'información de recepción',
    shr: 'Destinatario',
    qsrndzwmhywm: 'Por favor, introduzca su nombre chino o nombre en inglés',
    shdz: 'dirección de entrega',
    qsrndshdz: 'Por favor, introduzca su dirección de cosecha',
    lxdh: 'número de contacto',
    qsrndlxfs: 'Por favor, introduzca sus datos de contacto',
    yzm: 'captcha',
    qsryzm: 'Por favor, introduzca el código de verificación',
    qsrzqdsjh: 'Introduzca el número de teléfono móvil correcto',
    zfb: 'Alipay',
    wx: 'WeChat',
    gsjhybzy: 'Este número de teléfono ya está tomado',
    gsjhmyzcqdl: 'Este número de móvil está registrado, por favor inicie sesión'
  },
  home: {
    tjcp: 'Productos recomendados',
    ckgd: 'Ver más',
    jjfa: 'solución',
    bbznglpt: 'poner la plataforma de gestión inteligente',
    xmal: 'caso de proyecto',
    zlhzhb: 'socio estratégico',
    yljh: 'enlaces',
    bbscrk: 'Poner la entrada al centro comercial',
    bbptdl: 'iniciar sesión en la plataforma',
    gysrk: 'entrada de proveedores',
    hzhbrk: 'portal de socios',
    zsjmrk: 'entrada de entrada de inversión',
  },
  orderBox: {
    ddh: 'número de pedido',
    cjsj: 'tiempo de creación',
    ddxq: 'Detalles del pedido',
    dzf: 'A pagar',
    sdgb: 'apagado manual',
    qxdd: 'cancelar pedido',
    wzf: 'no remunerado',
    chgb: 'tiempo de espera',
    zfsb: 'Pago fallido',
    qzf: 'Ve a pagar',
    ckcg: 'salida exitosa',
    ywc: 'completado',
    kcwl: 'Ver logística',
    qrsh: 'confirmar recibo',
    qxzqxddyy: 'Seleccione un motivo de cancelación',
    qx: 'cancelar',
    qr: 'confirmar',
    qrgb: 'confirmar cierre',
    qxddcg: 'Pedido cancelado exitoso',
    nqryshm: '¿Estás seguro de que quieres llevarte la mercancía?',
    ts: 'pista',
    yqxsh: 'Cancelado',
    wldt:'Dinámica logística',
   sqsh:'Solicitar posventa',
   shfw:'Servicio postventa',
   qxzshfw:'Por favor, elija el tipo de post - venta',
   gzjj:'Resolución de fallas',
   hh:'Intercambio',
   tjtk:'Reembolso de devolución',
   tkbth:'Reembolso sin devolución'
  }

}
