// 德语
module.exports = {
  banner: {
    ljlj: 'Erfahren Sie mehr',
  },
  header: {
    bbzn: 'BaBa Intelligenz',
    hyn: 'Willkommen！',
    wddd: 'Bestellungen',
    gwc: 'Einkaufswagen',
    ptdl: 'Platform login',
    qdl: 'Anmelden',
    tcdl: 'Log out of login',
    dhl: 'Navigationsleiste'
  },

  nav: {
    bbwh: "Setzen Sie die Kultur",
    home: "Home page",
    flag: "{who} name is {who}",
    zhcs: 'Smart city',
    zhgc: 'Smart factory',
    zhxf: 'Weisheit Feuer',
    zhgd: 'Weisheit Website',
    zhsq: 'Intelligence community',
    zhxy: 'Weisheit campus',
    bbdt: 'Setzen Sie die dynamische',
    zwcx: "Die Echtheit der Abfrage",
    zlxz: "Download Informationen",
    rjxz: "Software-download",
    gysly: "Verkäufer Nachricht",
    khly: "Kunden Kommentar",
    bbjj: "Legen Einführung",
    ryzz: "Ehre",
    lxwm: "Kontaktieren Sie uns",
    hydt: 'Die Dynamik in der Branche',
    bbbk: 'Put Wikipedia',
    cpzx: 'Produkte',
    jjfa: 'Lösungen',
    xmal: 'Projekte',
    bbzx: 'Informationen',
    fwzc: 'Service',
    zsdl: 'Investoren',
    gybb: 'über uns',
    zxsp: 'Video',
    hylt: 'BBS',
    yhxy:'Nutzungsvereinbarung',
    ystk:'Datenschutzklausel',
    fhtkzc:'Rückerstattungsrichtlinien für den Versand',
  },
  product: {
    ljgm: 'Details',
    cpzxsy: 'Produktcenter',
    znaqm: 'Smart Helm',
    ywbjq: 'Rauchmelder',
    dwsb: 'Positionierung Uhr',
    zhyd: 'Weisheit Strom',
    znsb: 'Smart meter',
    znpt: 'Die Smart-Plattform',
    yyfc: 'Off-road-dumper',
    xfcl: 'Feuerwehrfahrzeuge',
    ss: 'Suche'
  },
  footer: {

  },
  solution: {
    ckgd: 'Mehr anzeigen'
  },
  common: {
    sy: 'Startseite',


  },
  breadcrumb: {
    xwdt: 'News Updates',
    bbdt: 'Setzen Sie die dynamische',
    hydt: 'Die Dynamik in der Branche',
    bbbk: 'Put Wikipedia',
    ljxq: 'Mehr erfahren'
  },
  serve: {
    fwzc: 'Service-support',
    zlxz: 'Download Informationen',
    rjxz: 'Software-download',
    gysly: 'Verkäufer Nachricht',
    yhxy:'Nutzungsvereinbarung',
    ystk:'Datenschutzklausel',
    fhtkzc:'Rückerstattungsrichtlinien für den Versand',
    zwcx: 'Die Echtheit der Abfrage',
    zwcx_placeholder: 'Bitte geben Sie das Produkt imei ein',
    cpxx: "Produktinformationen",
    pcy: "Qualitätssicherung/extra lange Garantie/sorgenfreier Betrieb",
    cpxh: "Produktmodell",
    ys: "Farbe",
    sbh: "Gerätenummer",
    ccrq: "Produktionsdatum",
    sccj: "Hersteller",
    zjy: 'Qualitätsinspektor',
    xsryxm: "Name des Vertriebspersonals",
    dlsmc: "Name des Agenten",
    gwrk: "Offizieller Website-Eingang"
  },
  goodsDetail: {
    price: 'Preis',
    number: "Menge",
    addgoodscan: 'In den Warenkorb',
    buynow: 'Jetzt kaufen'
  },
  safetyHat: {
    cpzxsy: 'Product Center Home Page',
    ss: 'Suche',
    buynow: 'Details'
  },
  fireEngine: {
    cpzxsy: 'Product Center Home Page',
    ss: 'Suche',
    buynow: 'Details'
  },
  platForm: {
    cpzxsy: 'Product Center Home Page',
    ss: 'Suche',
    buynow: 'Details'
  },
  smartElectricity: {
    cpzxsy: 'Product Center Home Page',
    ss: 'Suche',
    buynow: 'Details'
  },
  smokeDetector: {
    cpzxsy: 'Product Center Home Page',
    ss: 'Suche',
    buynow: 'Details'
  },
  touringCar: {
    cpzxsy: 'Product Center Home Page',
    ss: 'Suche',
    buynow: 'Details'
  },
  watch: {
    cpzxsy: 'Product Center Home Page',
    ss: 'Suche',
    buynow: 'Details'
  },
  waterMeter: {
    cpzxsy: 'Product Center Home Page',
    ss: 'Suche',
    buynow: 'Details'
  },
  dynamic: {
    home: "Home page",
    xwdt: 'News Updates'
  },
  dynamicDetail: {
    zz: "Autor"
  },
  dynamicBox: {
    ljxq: 'Mehr erfahren'
  },
  supplier: {
    qymc: 'Unternehmensname',
    qcrqymc: 'Bitte geben Sie den Firmennamen ein',
    xm: "Name",
    qsrxm: "itte einen Namen eingeben",
    yx: 'Email',
    qsuyx: 'Please enter email',
    dh: 'Telefon',
    qsrdh: "Bitte geben Sie eine Telefonnummer ein",
    nr: 'Inhalt',
    qsrnr: "Bitte Inhalt eingeben",
    tj: "Submit",
    cz: 'Zurücksetzen',
    qtxzqddhhm: 'Bitte geben Sie die richtige Telefonnummer ein',
    qtxzqdyx: 'Bitte geben Sie die korrekte E-Mail-Adresse ein',
    qsrqymc: 'Bitte geben Sie den Firmennamen ein',
    qsrsjh: "Bitte geben Sie Ihre Telefonnummer ein",
  },
  agentForm: {
    xm: "Name",
    qsrxm: "itte einen Namen eingeben",
    yx: 'Email',
    qsuyx: 'Please enter email',
    dh: 'Telefon',
    qsrdh: "Bitte geben Sie eine Telefonnummer ein",
    nr: 'Inhalt',
    qsrnr: "Bitte Inhalt eingeben",
    tj: "Submit",
    cz: 'Zurücksetzen',
    qtxzqddhhm: 'Bitte geben Sie die richtige Telefonnummer ein',
    qtxzqdyx: 'Bitte geben Sie die korrekte E-Mail-Adresse ein',
    qsrqymc: 'Bitte geben Sie den Firmennamen ein',
    qsrsjh: "Bitte geben Sie Ihre Telefonnummer ein",
  },
  about: {
    dh: 'Telefon',
    yx: 'E-Mail',
    zbgsdz: 'Firmensitz Adresse',
    zbgsmc:'Firmenname',
    fbgsmc:'Name des Vertriebsunternehmens',
    fbgsdz:'Adresse des Vertriebsunternehmens',
  },
  footerContent: {
    jzhxz: 'Jia Zhihui. download',
    qgzxfwrx: "Nationale Hotline",
    gsdzvalue: "Building 11, 4th Floor, Tangtou Nangang Third Industrial Park, Tangtou Community, Shiyan Street, Bao'an District, Shenzhen",
    gsdz: "Firmenadresse",
    gzwxgzh: "Folgen Sie dem öffentlichen WeChat-Konto",
    gzwb: "Folge Weibo",
    wxgzh: "Öffentliches WeChat-Konto",
    APPxz: "APP-Download",
    bq: "Shenzhen wird die intelligente Technologie Co., Ltd. alle Rechte vorbehalten",
    xz: "Laden Sie die App herunter",
    znsfcp: "Produkte für intelligente Justiz",
    znxfcp: "Intelligente Brandschutzprodukte",
    znafcp: "Intelligente Sicherheitsprodukte",
    znshcp: "Smart-Life-Produkte",
    znpj: "Intelligentes Zubehör",
    znglpt: "Intelligente Management-Plattform",
    zhcs: "Intelligente Stadt",
    zhgc: "Intelligente Fabrik",
    zhxf: "Intelligenter Brandschutz",
    zhgd: "Smarte Baustelle",
    zhsq: "Intelligente Gemeinschaft",
    zhxy: 'Smart Campus',
    jjfa: 'Lösung',
    cpzxsy: "Heimat der Produkte",
    bbzx: 'Geben Sie die Informationen ein',
    bbdt: 'Setzen Sie die Dynamik',
    hydt: 'Neuigkeiten aus der Branche',
    bbbk: 'Setzen Sie die Enzyklopädie',
    fwzc: "Service-Support",
    bbjj: "Setzen Sie die Einleitung",
    ryzz: "Ehre",
    gybb: 'Über den Griff',
    bbwh: "Setzen Sie die Kultur",
    lxwm: "Kontaktieren Sie uns",
    hzhb: "Partner",
    zlxz: 'Daten-Download',
    rjxz: 'Software-Download',
    gysly: 'Lieferantennachricht',
    zwcx: 'Authentizitätsabfrage',
    khly: "Kundennachricht",
    yhxy:'Nutzungsvereinbarung',
    ystk:'Datenschutzklausel',
    fhtkzc:'Rückerstattungsrichtlinien für den Versand',
  },
  login: {
    bbzn: "Setzen Sie die Intelligenz",
    sjh: "Mobiltelefonnummer",
    mm: 'Passwort',
    zczh: "Registrieren Sie ein Konto",
    dl: 'Login',
    qdl: "Bitte einloggen",
    zc: 'Registrieren',
    zh: "Kontonummer",
    qsrzh: 'Bitte Kontonummer eingeben',
    qsrmm: 'Bitte Passwort eingeben',
    qrmm: 'Passwort bestätigen',
    qzcsrmm: "Bitte geben Sie das Passwort erneut ein",
    qsrsjh: "Bitte geben Sie Ihre Handynummer ein",
    yzm: "captcha",
    qsryzm: "Bitte geben Sie den Verifizierungscode ein",
    cd: "Die Länge muss zwischen 6-12 Bit liegen",
    qtxzqdsjhm: "Bitte geben Sie die richtige Handynummer ein",
    mmtj: "Das Passwort muss Zahlen und Buchstaben enthalten, 6-20 Ziffern",
    mmbyz: 'Passwort-Inkonsistenz',
    hqyzm: "Holen Sie sich Captcha",
    get: 'nach dem erneuten Abrufen'
  },
  logisticsInfo: {
    wlxq: "Logistikdetails",
    ydhm: 'Frachtbriefnummer',
    wlgs: "Logistikunternehmen",
    kfdh: "Kundendienstnummer",
    wlgsgfwz: "Offizielle Website der Logistikunternehmen",
    zwwlxx: 'Keine logistischen Informationen'
  },
  MyAddress: {
    shdz: "Lieferadresse",
    tjxdz: "Neue Adresse hinzufügen",
    mr: "Standard",
    xg: "modifizieren",
    sc: 'löschen',
    shr: "Empfänger",
    shdq: 'Empfangsbereich',
    xxdz: "vollständige Anschrift",
    sjhm: 'Handynummer',
    scdzcg: 'Adresse erfolgreich gelöscht',
    xz: 'auswählen',
    dzbm: 'Adress-Alias',
    mrdz: 'Standardadresse',
    qsrsjrxm: 'Bitte geben Sie den Namen des Empfängers ein',
    qsrdz: 'Bitte Adresse eingeben',
    qsrxxdz: 'Bitte geben Sie die vollständige Adresse ein',
    sjhbnwk: "Handynummer darf nicht leer sein",
    sjhgscc: "Das Format der Handynummer ist falsch",
    xgdzcg: 'Adresse erfolgreich geändert',
    xinz: 'neu',
    tjdzcg: 'Adresse erfolgreich hinzugefügt'
  },
  MyOrder: {
    dd: 'bestellen',
    qbdd: 'Alle Bestellungen',
    dfk: "Ausstehend",
    dfh: "Zu versenden",
    dsh: 'Zu empfangen',
    ywc: 'abgeschlossen',
    my: 'pro Seite',
    tiao: 'Artikel'
  },
  orderDetail: {
    ddxq: 'Bestelldetails',
    ddh: "Bestellnummer",
    cjsj: 'Schöpfungszeit',
    shrx: "Informationen über den Empfänger",
    shr: "Empfänger",
    sjhm: 'Handynummer',
    shdz: "Lieferadresse",
    cptp: 'Produktbilder',
    cpmc: "Produktname",
    dj: 'Stückpreis',
    sl: 'Menge',
  },
  pay: {
    shrxx: "Informationen über den Empfänger",
    glshrdz: "Empfängeradressen verwalten",
    xg: "modifizieren",
    sc: 'löschen',
    tjxdz: 'Neue Adresse hinzufügen',
    sqdz: 'Adresse ausblenden',
    gddz: 'Weitere Adressen',
    spxx: "Produktinformationen",
    fhgwc: 'Zurück zum Warenkorb',
    yh: 'auf Lager',
    wu: "Nicht vorrätig",
    ddbz: 'Bestellnotizen',
    ts: 'Tipp: Bitte geben Sie keine Informationen über Zahlung, Quittung aus,',
    tjdd: 'Bestellung absenden',
    psz: 'Schiff nach',
    shdz: "Lieferadresse",
    shr: "Empfänger",
    zffs:'Zahlungsmethode',
    qxzndzffs:'Bitte wählen Sie Ihre Zahlungsart',
    jnzf:'Inlandszahlungen',
    jwzf:'Auslandszahlungen',
    qx:'Abbrechen',
    qr:'bestätigen'
  },
  payment: {
    ddtjcg: 'Bestellung erfolgreich eingereicht, bitte so schnell wie möglich bezahlen! ',
    qnjkwczf: "Bitte schließen Sie die Zahlung so schnell wie möglich ab, andernfalls wird die Bestellung automatisch storniert",
    dycsqx: "Bestellung wurde nach Zeitüberschreitung storniert",
    yfje: "Fälliger Betrag",
    yuan: 'yuan',
    qsywxsmzf: "Bitte verwenden Sie den WeChat-Scancode, um zu bezahlen",
    zfcghzdtz: 'automatischer Sprung nach erfolgreicher Zahlung, wenn Sie springen möchten, klicken Sie bitte auf die Schaltfläche, um manuell zu springen',
    cxzf: "Rückzahlung",
    zfb: 'Alipay',
    wx: 'WeChat',
    zfcg: 'Zahlungserfolg'
  },
  shopping: {
    tp: 'Bild',
    spmc: "Handelsname",
    dj: 'Stückpreis',
    xj: 'Zwischensumme',
    sl: 'Menge',
    cz: "Operation",
    jxll: 'Weiter surfen',
    gong: 'Gong',
    jcp: "Stückerzeugnis",
    hj: 'total',
    qjs: 'Geh zur Siedlung',
    cczjycgsp: "Mit dieser Aktion wird das Produkt entfernt, möchten Sie fortfahren?",
    ts: 'Hinweis',
    qr: 'bestätigen',
    qx: 'Abbrechen',
    yqxyc: 'nicht entfernt',
    ycspcg: 'Entfernung des Elements erfolgreich',
    xgslcg: 'Anzahl der erfolgreichen Änderungen',
    qxznyjsdsp: 'Bitte wählen Sie die Ware aus, die Sie prüfen möchten',
  },
  menu: {
    gwc: 'Warenkorb',
    ddzx: 'Bestellzentrale',
    wddd: 'Meine Bestellungen',
    shdz: 'Ernteadresse',
  },
  noLogin: {
    ljzf: 'Zahlung',
    qxss: 'Brandneu',
    sl: 'Menge',
    yfje: "Fälliger Betrag",
    gmxx: 'Kaufinformationen',
    shxx: 'Quittungsinformationen',
    shr: "Empfänger",
    qsrndzwmhywm: 'Bitte geben Sie Ihren chinesischen Namen oder englischen Namen ein',
    shdz: "Lieferadresse",
    qsrndshdz: 'Bitte geben Sie Ihre Ernteadresse ein',
    lxdh: 'Kontaktnummer',
    qsrndlxfs: 'Bitte geben Sie Ihre Kontaktdaten ein',
    yzm: 'captcha',
    qsryzm: 'Bitte geben Sie den Verifizierungscode ein',
    qsrzqdsjh: 'Bitte geben Sie die richtige Handynummer ein',
    zfb: 'Alipay',
    wx: 'WeChat',
    gsjhybzy: "Diese Telefonnummer ist bereits vergeben",
    gsjhmyzcqdl: 'Diese Handynummer ist registriert, bitte anmelden'
  },
  home: {
    tjcp: 'Empfohlene Produkte',
    ckgd: 'Mehr sehen',
    jjfa: 'Lösung',
    bbznglpt: "Setzen Sie die intelligente Management-Plattform",
    xmal: 'Projektfall',
    zlhzhb: "Strategischer Partner",
    yljh: 'Links',
    bbscrk: "Setzen Sie den Eingang zum Einkaufszentrum ein",
    bbptdl: "Melden Sie sich bei der Plattform an",
    gysrk: "Lieferanteneintrag",
    hzhbrk: 'Partnerportal',
    zsjmrk: "Investment Entry Entry",
  },
  orderBox: {
    ddh: "Bestellnummer",
    cjsj: 'Schöpfungszeit',
    ddxq: 'Bestelldetails',
    dzf: "Zu bezahlen",
    sdgb: "Manuelles Herunterfahren",
    qxdd: 'Bestellung stornieren',
    wzf: "unbezahlt",
    chgb: "Auszeit",
    zfsb: "Zahlung fehlgeschlagen",
    qzf: 'Geh bezahlen',
    ckcg: "Outbound erfolgreich",
    ywc: 'abgeschlossen',
    kcwl: 'View Logistics',
    qrsh: 'Empfang bestätigen',
    qxzqxddyy: 'Bitte wählen Sie einen Grund für die Stornierung',
    qx: 'Abbrechen',
    qr: 'bestätigen',
    qrgb: 'Bestätigen Sie den Abschluss',
    qxddcg: 'Abgebrochene Bestellung erfolgreich',
    nqryshm: "Bist du sicher, dass du die Ware mitnehmen willst?",
    ts: 'Hinweis',
    yqxsh: 'Abgesagt',
    wldt:'Logistikdynamik',
    sqsh:'Beantragen Sie den Kundendienst',
    shfw:'Kundendienst',
    quzshfw:'Bitte wählen Sie den After-Sales-Typ',
    gzjj:'Fehlerbehebung',
    hh:'Austausch',
    thtk:'Rücksendungen und Rückerstattungen',
    tkbth:'Rückerstattung nicht zurückgeben'
  }

}
