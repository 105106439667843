// 俄语
module.exports = {
  banner: {
    ljlj: 'Узнать подробности ',
  },
  header: {
    bbzn: 'BaBa Умный ',
    hyn: 'Добро пожаловать!',
    wddd: 'Заказы',
    gwc: 'Грузовой вагон',
    ptdl: 'Входить',
    qdl: 'Регистрация',
    tcdl: 'Выйти из',
    dhl: 'Навигационная панель'
  },
  
  nav: {
    bbwh: "Посадить культуру",
    home: "Главная страница",
    flag: "{who}名字是{what}",
    zhcs: 'Умный город',
    zhgc: 'Умная фабрика',
    zhxf: 'Интеллектуальная противопожарная защита',
    zhgd: 'Умная строительная площадка',
    zhsq: 'Умное сообщество',
    zhxy: 'Умный кампус',
    bbdt: 'Обрабатывать динамику',
    zwcx: "Запрос на подлинность",
    zlxz: "Скачать",
    rjxz: "Загрузка программного обеспечения",
    gysly: "Сообщение поставщика",
    khly: "Сообщение клиента",
    bbjj: "Введение в обращение",
    ryzz: "Честь и Квалификация",
    lxwm: "Связаться с нами",
    hydt: 'Динамика отрасли',
    bbbk: 'Энциклопедия ручек',
    cpzx: 'Товары',
    jjfa: 'Решения',
    xmal: 'Проекты',
    bbzx: 'Информация',
    fwzc: 'Услуги',
    zsdl: 'Инвесторы',
    gybb: 'О нас',
    zxsp: 'Видео',
    hylt: 'BBS',
    yhxy: 'Пользовательское соглашение',
    ystk:"Условия конфиденциальности",
    fhtkzc:"Политика возврата отгрузок",
  },
  product: {
    ljgm: 'Подробно',
    cpzxsy: 'Продукты',
    znaqm: 'Умный шлем',
    ywbjq: 'Дымовая сигнализация',
    dwsb: 'Часы для позиционирования',
    zhyd: 'Умное электричество',
    znsb: 'Умный счетчик воды',
    znpt: 'Интеллектуальная платформа',
    yyfc: 'Опрокидывание на бездорожье',
    xfcl: 'Противопожарная машина',
    ss: 'Поиск'
  },
  footer: {

  },
  solution: {
    ckgd: 'Узнать больше'
  },
  common: {
    sy: 'Главная страница',


  },
  breadcrumb: {
    xwdt: 'Динамика новостей',
    bbdt: 'Обрабатывать динамику',
    hydt: 'Динамика отрасли',
    bbbk: 'Энциклопедия ручек',
    ljxq: 'Узнайте подробнее'
  },
  serve: {
    fwzc: 'Услуги',
    zlxz: 'Скачать',
    rjxz: 'Применение',
    gysly: 'Сообщение',
    yhxy: 'Пользовательское соглашение',
    ystk:"Условия конфиденциальности",
    fhtkzc:"Политика возврата отгрузок",
    zwcx: 'Запрос на подлинность',
    zwcx_placeholder: 'Пожалуйста, введите продукт imei',
    cpxx: "Информация о продукте",
    pcy: "Гарантия качества / сверхдлинная гарантия качества / бесперебойная работа",
    cpxh: "Модель продукта",
    ys: "Цвет",
    sbh: "Номер устройства",
    ccrq: "Дата выпуска",
    sccj: "Производитель",
    zjy: 'Инспектор качества',
    xsryxm: "Имя продавца",
    dlsmc: "Имя агента",
    gwrk: "Официальный вход"
  },
  goodsDetail: {
    price: "Цены",
    number: "Количество",
    addgoodscat: "Добавить корзину покупок",
    buynow: "Купить сейчас"
  },
  safetyHat: {
    cpzxsy: 'Главная страница Продуктового центра',
    ss: 'Поиск',
    buynow: 'Подробно'
  },
  fireEngine: {
    cpzxsy: 'Главная страница Продуктового центра',
    ss: 'Поиск',
    buynow: 'Подробно'
  },
  platForm: {
    cpzxsy: 'Главная страница Продуктового центра',
    ss: 'Поиск',
    buynow: 'Подробно'
  },
  smartElectricity: {
    cpzxsy: 'Главная страница Продуктового центра',
    ss: 'Поиск',
    buynow: 'Подробно'
  },
  smokeDetector: {
    cpzxsy: 'Главная страница Продуктового центра',
    ss: 'Поиск',
    buynow: 'Подробно'
  },
  touringCar: {
    cpzxsy: 'Главная страница Продуктового центра',
    ss: 'Поиск',
    buynow: 'Подробно'
  },
  watch: {
    cpzxsy: 'Главная страница Продуктового центра',
    ss: 'Поиск',
    buynow: 'Подробно'
  },
  waterMeter: {
    cpzxsy: 'Главная страница Продуктового центра',
    ss: 'Поиск',
    buynow: 'Подробно'
  },
  dynamic: {
    home: "Главная страница",
    xwdt: 'Динамика новостей'
  },
  dynamicDetail: {
    zz: "Автор"
  },
  dynamicBox: {
    ljxq: 'Узнайте подробнее'
  },
  supplier: {
    qymc: 'название компании',
    qcrqymc: 'Введите название компании',
    xm: "Имя",
    qsrxm: "Введите имя",
    yx: 'Почтовый ящик',
    qsuyx: 'Пожалуйста, введите почтовый ящик',
    dh: 'Телефон',
    qsrdh: "Пожалуйста, введите телефон",
    nr: 'Содержание',
    qsrnr: "Пожалуйста, введите содержимое",
    tj: "Представлено",
    cz: 'Сбросить',
    qtxzqddhhm: 'Пожалуйста, заполните правильный телефонный номер',
    qtxzqdyx: ' Пожалуйста, заполните правильный почтовый ящик',
    qsrqymc: 'Введите название компании',
    qsrsjh: "Пожалуйста, введите номер телефона",
  },
  agentForm: {
    xm: "Имя",
    qsrxm: "Введите имя",
    yx: 'Почтовый ящик',
    qsuyx: 'Пожалуйста, введите почтовый ящик',
    dh: 'Телефон',
    qsrdh: "Пожалуйста, введите телефон",
    nr: 'Содержание',
    qsrnr: "Пожалуйста, введите содержимое",
    tj: "Представлено",
    cz: 'Сбросить',
    qtxzqddhhm: 'Пожалуйста, заполните правильный телефонный номер',
    qtxzqdyx: ' Пожалуйста, заполните правильный почтовый ящик',
    qsrqymc: 'Введите название компании',
    qsrsjh: "Пожалуйста, введите номер телефона",
  },
  about: {
    dh: 'Телефон',
    yx: 'почта',
    zbgsdz: 'Адрес штаб - квартиры',
    zbgsmc:'Название компании',
    fbgsmc:'Распределение названий компаний',
    fbgsdz:'Распределительная компания'
  },
  footerContent: {
    jzhxz: 'Цзя Чжихэи download',
    qgzxfwrx: 'Национальная горячая линия',
    gsdzvalue: 'Shenzhen Baoan District Xixiang Baoan Avenue access road Zong Tai Yong Li wisdom Garden 3 floors',
    gsdz: 'Адрес компании',
    gzwxgzh: "Подписаться на публичный аккаунт WeChat",
    gzwb: 'Подписаться на Weibo',
    wxgzh: "Публичная учетная запись WeChat",
    APPxz: "Загрузка приложения",
    bq: 'Шэньчжэнь поставит интеллектуальную технологию Co., Ltd. все права защищены',
    xz: 'Скачать приложение',
    znsfcp: 'Продукты умного правосудия',
    znxfcp: 'интеллектуальные продукты противопожарной защиты',
    znafcp: 'Интеллектуальные продукты безопасности',
    znshcp: 'Продукты Smart Life',
    znpj: 'умные аксессуары',
    znglpt: 'Интеллектуальная платформа управления',
    zhcs: 'Умный город',
    zhgc: 'Умная фабрика',
    zhxf: 'умная противопожарная защита',
    zhgd: 'Умная строительная площадка',
    zhsq: 'Умное сообщество',
    zhxy: 'Умный кампус',
    jjfa: 'решение',
    cpzxsy: 'Дом продуктов',
    bbzx: 'Поместите информацию',
    bbdt: 'Положите динамику',
    hydt: 'Новости отрасли',
    bbbk: 'Положите энциклопедию',
    fwzc: 'Сервисная поддержка',
    bbjj: "Поставьте вступление",
    ryzz: "честь",
    gybb: 'О ручке',
    bbwh: "Положите культуру",
    lxwm: "Свяжитесь с нами",
    hzhb: "Партнер",
    zlxz: 'загрузка данных',
    rjxz: 'загрузка программного обеспечения',
    gysly: 'Сообщение поставщика',
    zwcx: 'запрос подлинности',
    khly: "сообщение клиенту",
    yhxy: 'Пользовательское соглашение',
    ystk:"Условия конфиденциальности",
    fhtkzc:"Политика возврата отгрузок",
  },
  login: {
    bbzn: "Поставь разведку",
    sjh: 'номер мобильного телефона',
    mm: 'пароль',
    zczh: "зарегистрировать аккаунт",
    dl: 'логин',
    qdl: "Пожалуйста, войдите",
    zc: 'Регистрация',
    zh: "номер счета",
    qsrzh: 'Пожалуйста, введите номер счета',
    qsrmm: 'Пожалуйста, введите пароль',
    qrmm: 'Подтвердите пароль',
    qzcsrmm: "Пожалуйста, введите пароль",
    qsrsjh: "Пожалуйста, введите номер своего мобильного телефона",
    yzm: "капча",
    qsryzm: "Пожалуйста, введите проверочный код",
    cd: "Длина должна быть от 6 до 12 бит",
    qtxzqdsjhm: "Пожалуйста, введите правильный номер мобильного телефона",
    mmtj: "Пароль должен содержать цифры и буквы, 6-20 цифр",
    mmbyz: 'несогласованность пароля',
    hqyzm: 'Получить капчу',
    get: ' после рефетирования'

  },
  logisticsInfo: {
    wlxq: 'Детали логистики',
    ydhm: 'Номер накладной',
    wlgs: 'Логистическая компания',
    kfdh: 'Номер службы поддержки клиентов',
    wlgsgfwz: 'Официальный сайт логистических компаний',
    zwwlxx: 'Нет логистической информации'
  },
  MyAddress: {
    shdz: 'адрес доставки',
    tjxdz: 'Добавить новый адрес',
    mr: 'По умолчанию',
    xg: 'модифицировать',
    sc: 'удалить',
    shr: 'Грузополучатель',
    shdq: 'Зона приема',
    xxdz: 'полный адрес',
    sjhm: 'номер мобильного телефона',
    scdzcg: 'Адрес успешно удален',
    xz: 'выбрать',
    dzbm: 'псевдоним адреса',
    mrdz: 'адрес по умолчанию',
    qsrsjrxm: 'Пожалуйста, введите имя получателя',
    qsrdz: 'Пожалуйста, введите адрес',
    qsrxxdz: 'Пожалуйста, введите полный адрес',
    sjhbnwk: 'Номер мобильного телефона не может быть пустым',
    sjhgscc: 'Неправильный формат номера мобильного телефона',
    xgdzcg: 'адрес успешно изменен',
    xinz: 'новый',
    tjdzcg: 'адрес успешно добавлен'
  },
  MyOrder: {
    dd: 'порядок',
    qbdd: 'Все заказы',
    dfk: 'В ожидании',
    dfh: 'Будет отправлено',
    dsh: 'Будет получено',
    ywc: 'Завершено',
    my: 'за страницу',
    tiao: 'Статья'
  },
  orderDetail: {
    ddxq: 'Сведения о заказе',
    ddh: 'номер заказа',
    cjsj: 'время создания',
    shrx: 'Информация о грузополучателе',
    shr: 'Грузополучатель',
    sjhm: 'номер мобильного телефона',
    shdz: 'адрес доставки',
    cptp: 'Изображения продуктов',
    cpmc: 'Название продукта',
    dj: 'цена за единицу',
    sl: 'количество',

  },
  pay: {

    shrxx: 'Информация о грузополучателе',
    glshrdz: 'Управление адресами получателей',
    xg: 'модифицировать',
    sc: 'удалить',
    tjxdz: 'добавить новый адрес',
    sqdz: 'свернуть адрес',
    gddz: 'больше адресов',
    spxx: 'Информация о продукте',
    fhgwc: 'Снова в корзину',
    yh: 'В наличии',
    wu: 'нет в наличии',
    ddbz: 'Примечания к заказу',
    ts: 'Совет: Пожалуйста, не заполняйте информацию об оплате, квитанции',
    tjdd: 'Отправить заказ',
    psz: 'отправить в',
    shdz: 'адрес доставки',
    shr: 'Грузополучатель',
    zffs:'Способы оплаты',
    qxzndzffs:'Пожалуйста, выберите свой способ оплаты.',
    jnzf:'Внутренние платежи',
    jwzf:'Зарубежные платежи',
    qx:'Cancelación',
    qr:'Отменить'
  },
  payment: {
    ddtjcg: 'Заказ успешно отправлен, пожалуйста, оплатите как можно скорее!',
    qnjkwczf: 'Пожалуйста, завершите оплату как можно скорее, иначе заказ будет автоматически отменен',
    dycsqx: 'Заказ был отменен после тайм-аута',
    yfje: 'Сумма к оплате',
    yuan: 'юань',
    qsywxsmzf: 'Пожалуйста, используйте код сканирования WeChat для оплаты',
    zfcghzdtz: 'Автоматический прыжок после успешной оплаты, если вы хотите прыгнуть, пожалуйста, нажмите кнопку, чтобы прыгнуть вручную',
    cxzf: 'погасить',
    zfb: 'Alipay',
    wx: 'WeChat',
    zfcg: 'Успех платежа'
  },
  shopping: {
    tp: 'изображение',
    spmc: 'Торговое наименование',
    dj: 'цена за единицу',
    xj: 'промежуточный итог',
    sl: 'количество',
    cz: 'операция',
    jxll: 'Продолжить просмотр',
    gong: 'Гонг',
    jcp: 'штучный продукт',
    hj: 'всего',
    qjs: 'Перейти к поселению',
    cczjycgsp: 'Это действие удалит продукт, вы хотите продолжить?',
    ts: 'подсказка',
    qr: 'подтвердить',
    qx: 'отмена',
    yqxyc: 'неудаленный',
    ycspcg: 'Удаление элемента выполнено успешно',
    xgslcg: 'количество успешных модификаций',
    qxznyjsdsp: 'Пожалуйста, выберите товар, который вы хотите проверить',
  },
  menu: {
    gwc: 'корзина',
    ddzx: 'Центр заказов',
    wddd: 'Мои приказы',
    shdz: 'Адрес урожая',
  },
  noLogin: {
    ljzf: 'Выплаты',
    qxss: 'Совершенно новый',
    sl: 'количество',
    yfje: 'Сумма к оплате',
    gmxx: 'Информация о покупке',
    shxx: 'информация о получении',
    shr: 'Грузополучатель',
    qsrndzwmhywm: 'Пожалуйста, введите свое китайское имя или английское имя',
    shdz: 'адрес доставки',
    qsrndshdz: 'Пожалуйста, введите адрес сбора урожая',
    lxdh: 'контактный номер',
    qsrndlxfs: 'Пожалуйста, введите свои контактные данные',
    yzm: 'капча',
    qsryzm: 'Пожалуйста, введите проверочный код',
    qsrzqdsjh: 'Пожалуйста, введите правильный номер мобильного телефона',
    zfb: 'Alipay',
    wx: 'WeChat',
    gsjhybzy: 'Этот номер телефона уже занят',
    gsjhmyzcqdl: 'Этот номер мобильного телефона зарегистрирован, пожалуйста, авторизуйтесь'
  },
  home: {
    tjcp: 'Рекомендуемые продукты',
    ckgd: 'Видеть больше',
    jjfa: 'решение',
    bbznglpt: 'Положите интеллектуальную платформу управления',
    xmal: 'Кейс проекта',
    zlhzhb: 'стратегический партнер',
    yljh: 'ссылки',
    bbscrk: 'Поставьте вход в торговый центр',
    bbptdl: 'Войдите на платформу',
    gysrk: 'Вход поставщика',
    hzhbrk: 'Партнерский портал',
    zsjmrk: 'Вход в инвестиции',

  },
  orderBox: {
    ddh: 'номер заказа',
    cjsj: 'время создания',
    ddxq: 'Сведения о заказе',
    dzf: 'Чтобы заплатили',
    sdgb: 'ручное выключение',
    qxdd: 'отменить заказ',
    wzf: 'неоплачиваемый',
    chgb: 'тайм-аут выключен',
    zfsb: 'Платеж не удался',
    qzf: 'Иди плати',
    ckcg: 'исходящий успешный',
    ywc: 'Завершено',
    kcwl: 'Посмотреть логистику',
    qrsh: 'Подтвердите получение',
    qxzqxddyy: 'Пожалуйста, выберите причину отмены',
    qx: 'отмена',
    qr: 'подтвердить',
    qrgb: 'подтвердить закрытие',
    qxddcg: 'Отмененный заказ выполнен',
    nqryshm: 'Ты уверен, что хочешь забрать товар?',
    ts: 'подсказка',
    yqxsh: 'Отменено',
    wldt:'Динамика логистики',
   sqsh:'Заявка после продажи',
   shfw:'Послепродажное обслуживание',
   qxzshfw:'Выберите послепродажный тип',
   gzjj:"Устранение неполадок",
   hh:'Обмен',
   thtk:'Возврат',
   tkbth:'Возврат невозврата'
  }


}
