import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/axios'
import apiPath from './api/apiPath'
import translate from './api/translate'
import weixin from 'weixin-js-sdk'
import './rem/rem'
import './assets/less/style.less'
import 'element-ui/lib/theme-chalk/index.css';
import './untils/elementUI'
import updateLocalStorage from './untils/updateLocalStorage'
import i18n from './i18n/index.js'

Vue.config.productionTip = false

Vue.prototype.api = api;
Vue.prototype.$wx = weixin
Vue.prototype.apiPath = apiPath;
Vue.prototype.$onlinet = translate

Vue.use(updateLocalStorage);

import { WOW } from 'wowjs'
import animated from 'wowjs/css/libs/animate.css'
import axios from 'axios'
//这里需要将样式用起来，
Vue.use(animated)
Vue.prototype.isMobile = function () {
  var userAgentInfo = navigator.userAgent;

  var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

  var mobile_flag = false;

  //根据userAgent判断是否是手机
  for (var v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = true;
      break;
    }
  }
  var screen_width = window.screen.width;
  var screen_height = window.screen.height;

  //根据屏幕分辨率判断是否是手机
  if (screen_width > 325 && screen_height < 750) {
    mobile_flag = true;
  }

  return mobile_flag;
}
//创建全局实例
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // 需要执行动画的元素的 class
  animateClass: 'animated', //animation.css 动画的 class
  offset: 0, // 距离可视区域多少开始执行动画
  mobile: true, // 是否在移动设备上执行动画
  live: true, // 异步加载的内容是否有效
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// Vue.use(VueAwesomeSwiper)
const { aMapSecurityJsCode } = require("./components/config");
// 高德安全密钥
if (aMapSecurityJsCode) {
  window._AMapSecurityConfig = {
    securityJsCode: aMapSecurityJsCode,
  };
}
Vue.config.productionTip = false
Vue.prototype.moneyfilter = function (cny) {
  if (!localStorage.getItem('lang')) {
    return '￥' + cny
  } else if (localStorage.getItem('lang') == 'zh' || localStorage.getItem('lang') == 'cn') {
    return '￥' + cny
  } else {
    if (localStorage.getItem('rate') && localStorage.getItem('rate') != 'undefined') {
      console.log(localStorage.getItem('rate'))
      return '$' + (cny * localStorage.getItem('rate')).toFixed(2)
    } else {
      axios.get('https://api.it120.cc/gooking/forex/rate?fromCode=USD&toCode=CNY').then(res => {
        console.log(res)
        localStorage.setItem('rate', res.data.data.rate)
        return '$' + (cny * res.data.data.rate).toFixed(2)
      })
    }
  }
}
new Vue({
  router,
  store,
  render: h => h(App),
  // 预渲染
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  i18n
}).$mount('#app')
